export const server = 'https://cellocharge.com/PortalDashboard';

export const webAPIUrl = `${server}/api`;

export const authSettings = {
  domain: 'https://dev-u4xedsjc.us.auth0.com/',
  client_id: 'dev-u4xedsjc',
  redirect_uri: window.location.origin + '/signin-callback',
  scope: 'openid profile QandAAPI email',
  audience: 'https://eshop_proj',
};
