import ChartistGraph from "react-chartist";
import { Link } from "react-router-dom";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Language from "@material-ui/icons/Language";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CellochargeSite from "views/Maps/CellochargeSite";
import React, { useState, useEffect } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import axios from "axios";
import "./Dashboard.scss";
import * as configData from "../../appConfig.json";

const il_flag = require("assets/img/flags/IL.png");
//IL

const BASE_URL = configData.SERVER_URL + "api";
const defaultFormat = "YYYY-MM-DDTHH:mm:ss";

const dayOfWeek = [
  {
    key: "1",
    value: "א"
  },
  {
    key: "2",
    value: "ב"
  },
  {
    key: "3",
    value: "ג"
  },
  {
    key: "4",
    value: "ד"
  },
  {
    key: "5",
    value: "ה"
  },
  {
    key: "6",
    value: "ו"
  },
  {
    key: "7",
    value: "ז"
  }
];

const monthOfYear = [
  {
    key: "1",
    value: "ינואר"
  },
  {
    key: "2",
    value: "פבר"
  },
  {
    key: "3",
    value: "מרץ"
  },
  {
    key: "4",
    value: "אפר"
  },
  {
    key: "5",
    value: "מאי"
  },
  {
    key: "6",
    value: "יוני"
  },
  {
    key: "7",
    value: "יולי"
  },
  {
    key: "8",
    value: "אוג"
  },
  {
    key: "9",
    value: "ספט"
  },
  {
    key: "10",
    value: "אוק"
  },
  {
    key: "11",
    value: "נוב"
  },
  {
    key: "12",
    value: "דצמ"
  }
];

const useStyles = makeStyles(styles);

const getNumber_K_M = number => {
  const THOUSAND = 1000;
  const MILLION = THOUSAND * THOUSAND;
  if (number < THOUSAND) {
    return number;
  }
  if (number > MILLION) {
    number = number / MILLION;
    return Math.floor(number) + " מיליון ";
  }
  if (number > THOUSAND && number < MILLION) {
    number = number / THOUSAND;
    return Math.floor(number) + " אלף ";
  }
  return number;
};

export default function Dashboard() {
  MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth;

  const [consumptionData, setConsumptionData] = useState({
    totalConsumption: "-",
    totalConsumptionTime: "-",
    totalChargedVehicles: "-",
    totalCO2Saved: "-"
  });

  const [operatorsArr, setOperatorsArr] = useState([]);

  const [dailyGraphData, setDailyGraphData] = useState({
    labels: [],
    series: [[]],
    options: completedTasksChart.options
  });

  const [weeklyGraphData, setWeeklyGraphData] = useState({
    labels: [],
    series: [[]],
    options: dailySalesChart.options
  });

  const [yearlyGraphData, setYearlyGraphData] = useState({
    labels: [],
    series: [[]],
    options: emailsSubscriptionChart.options
  });

  const [hourlyServerData, setHourlyServerData] = useState([]);
  const [weeklyServerData, setWeeklyServerData] = useState([]);
  const [yearlyServerData, setYearlyServerData] = useState([]);
  const [openWeeklyDatePicker, setOpenWeeklyDatePicker] = useState(false);
  const [openYearlyDatePicker, setOpenYearlyDatePicker] = useState(false);
  const [openHourlyTimePicker, setOpenHourlyTimePicker] = useState(false);
  const [hourlySelectedTime, setHourlySelectedTime] = useState(
    moment().format(defaultFormat)
  );
  const [weeklySelectedTime, setWeeklySelectedTime] = useState(
    moment().format(defaultFormat)
  );
  const [yearlySelectedTime, setYearlySelectedTime] = useState(
    moment().format(defaultFormat)
  );

  const getDailyConsumptionData = () => {
    const Consumption_URL = BASE_URL + "/TotalConsumptionInfo";
    axios
      .get(Consumption_URL)
      .then(response => {
        console.info("response.data", response.data);
        setConsumptionData({
          totalConsumption: response.data.totalConsumption,
          totalConsumptionTime: response.data.totalConsumptionTime,
          totalChargedVehicles: response.data.totalChargedVehicles,
          totalCO2Saved: response.data.totalCO2Saved
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getOperatorsData = () => {
    const OPERATORS_URL = BASE_URL + "/ProviderInfo";
    
    axios
      .get(OPERATORS_URL)
      .then(response => {
        console.info("operators.data", response.data);
        setOperatorsArr(response.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getHourlyTotalConsumptionInfo = date => {
    const HOURLY_data = BASE_URL + "/TotalConsumptionInfo/hourly/" + date;
    axios
      .get(HOURLY_data)
      .then(response => {
        console.info("HOURLY_data", response.data);
        setHourlyServerData(response.data);
        const hour = moment(date).hour();
        let labelsHour = [];
        let series = [[]];
        for (let indexHour = 1; indexHour < 13; indexHour++) {
          let hourTd = hour + indexHour * 2;
          hourTd = hourTd > 23 ? hourTd - 24 : hourTd;
          labelsHour.push(hourTd.toString());
          const consumption = response.data.find(
            item => item.consumptionTime == hourTd
          )?.consumption;
          series[0].push(consumption);
        }

        let completedTasksChartOptions = dailyGraphData.options;
        const highSeries = Math.max(...series[0]);
        completedTasksChartOptions.high = highSeries;
        const dailyGraphDataValues = {
          labels: labelsHour,
          series: series,
          options: completedTasksChartOptions
        };
        console.log("dailyGraphDataValues", dailyGraphDataValues);
        setDailyGraphData(dailyGraphDataValues);
      })
      .catch(err => {
        console.error(err);
      });
  };
  const getWeeklyTotalConsumptionInfo = date => {
    const WEEKLY_data = BASE_URL + "/TotalConsumptionInfo/weekly/" + date;
    axios
      .get(WEEKLY_data)
      .then(response => {
        console.info("WEEKLY_data", response.data);
        setWeeklyServerData(response.data);
        const day = moment(date).day() + 1;
        let labelsWeekly = [];
        let series = [[]];
        for (let weeklyIndex = 1; weeklyIndex < 8; weeklyIndex++) {
          let weeklyLabel = day + weeklyIndex;
          weeklyLabel = weeklyLabel > 7 ? weeklyLabel - 7 : weeklyLabel;
          const findDayHebrew = dayOfWeek.find(d => d.key == weeklyLabel).value;
          labelsWeekly.push(findDayHebrew);
          const consumption = response.data.find(
            item => item.consumptionDay == weeklyLabel
          )?.consumption;
          series[0].push(consumption);
        }
        let completedTasksChartOptions = weeklyGraphData.options;
        const highSeries = Math.max(...series[0]);
        completedTasksChartOptions.high = highSeries;
        const weeklyGraphDataValues = {
          labels: labelsWeekly,
          series: series,
          options: completedTasksChartOptions
        };
        setWeeklyGraphData(weeklyGraphDataValues);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getYearlyTotalConsumptionInfo = date => {
    const YEARLY_data = BASE_URL + "/TotalConsumptionInfo/yearly/" + date;
    axios
      .get(YEARLY_data)
      .then(response => {
        console.info("YEARLY_data", response.data);
        setYearlyServerData(response.data);
        const month = moment(date).month() + 1;
        let labelsMonth = [];
        let series = [[]];
        for (let monthIndex = 1; monthIndex < 13; monthIndex++) {
          let monthLabel = month + monthIndex;
          monthLabel = monthLabel > 12 ? monthLabel - 12 : monthLabel;
          const findMonthHebrew = monthOfYear.find(d => d.key == monthLabel)
            .value;
          labelsMonth.push(findMonthHebrew);
          const consumption = response.data.find(
            item => item.consumptionMonth == monthLabel
          )?.consumption;
          series[0].push(consumption);
        }
        let completedTasksChartOptions = yearlyGraphData.options;
        const highSeries = Math.max(...series[0]);
        completedTasksChartOptions.high = highSeries;
        const yearlyGraphDataValues = {
          labels: labelsMonth,
          series: series,
          options: completedTasksChartOptions
        };
        setYearlyGraphData(yearlyGraphDataValues);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    getDailyConsumptionData();
    getOperatorsData();
    getHourlyTotalConsumptionInfo(moment().format(defaultFormat));
    getWeeklyTotalConsumptionInfo(moment().format(defaultFormat));
    getYearlyTotalConsumptionInfo(moment().format(defaultFormat));
  }, []);

  const setTableData = () => {
    let tableData = [
      ["לוגו", "שם המפעיל", "תחנות זמינות", "מחיר ממוצע"]
    ];
    operatorsArr.forEach(operator => {
      const tableRow = [
        <img className={'operator-logo'} src={operator.imageUrl} alt="operator logo" key={"flag"} />,
        operator.providerName,
        operator.availableStationsCounter,
        operator.averagePrice > 0 ? operator.averagePrice : '-'
      ];
      tableData.push(tableRow);
    });
    return tableData;
  };

  const getHourlyAverageGraph = () => {
    let sumConsumption = 0;
    let sumChangePersent = 0;
    hourlyServerData.forEach(item => (sumConsumption += item.consumption));
    hourlyServerData.forEach(item => (sumChangePersent += item.changePersent));
    const color = sumChangePersent > sumConsumption ? "red" : "green";
    return (
      <p className={classes.cardCategory}>
        <span style={{ color: color }}>
          {sumChangePersent > sumConsumption ? (
            <ArrowDownward className={classes.upArrowCardCategory} />
          ) : (
            <ArrowUpward className={classes.upArrowCardCategory} />
          )}
          {sumConsumption - sumChangePersent}%
        </span>{" "}
        ביחס ליום קודם
      </p>
    );
  };

  const getWeeklyAverageGraph = () => {
    let sumConsumption = 0;
    let sumChangePersent = 0;
    weeklyServerData.forEach(item => (sumConsumption += item.consumption));
    weeklyServerData.forEach(item => (sumChangePersent += item.changePersent));
    const color = sumChangePersent > sumConsumption ? "red" : "green";
    return (
      <p className={classes.cardCategory}>
        <span style={{ color: color }}>
          {sumChangePersent > sumConsumption ? (
            <ArrowDownward className={classes.upArrowCardCategory} />
          ) : (
            <ArrowUpward className={classes.upArrowCardCategory} />
          )}
          {sumConsumption - sumChangePersent}%
        </span>{" "}
        ביחס לשבוע קודם
      </p>
    );
  };

  const getYearlyAverageGraph = () => {
    let sumConsumption = 0;
    let sumChangePersent = 0;
    yearlyServerData.forEach(item => (sumConsumption += item.consumption));
    yearlyServerData.forEach(item => (sumChangePersent += item.changePersent));
    const color = sumChangePersent > sumConsumption ? "red" : "green";
    return (
      <p className={classes.cardCategory}>
        <span style={{ color: color }}>
          {sumChangePersent > sumConsumption ? (
            <ArrowDownward className={classes.upArrowCardCategory} />
          ) : (
            <ArrowUpward className={classes.upArrowCardCategory} />
          )}
          {sumConsumption - sumChangePersent}%
        </span>{" "}
        ביחס לשנה קודמת
      </p>
    );
  };

  const onWeeklyDateChange = date => {
    console.log(date);
    getWeeklyTotalConsumptionInfo(moment(date).format(defaultFormat));
    setWeeklySelectedTime(moment(date).format(defaultFormat));
    setOpenWeeklyDatePicker(false);
  };

  const onYearlyDateChange = date => {
    console.log(date);
    getYearlyTotalConsumptionInfo(moment(date).format(defaultFormat));
    setYearlySelectedTime(moment(date).format(defaultFormat));
    setOpenYearlyDatePicker(false);
  };

  const onHourlyTimeChange = date => {
    console.log(moment(date).format(defaultFormat));
    getHourlyTotalConsumptionInfo(moment(date).format(defaultFormat));
    setHourlySelectedTime(moment(date).format(defaultFormat));
    setOpenHourlyTimePicker(false);
  };

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="card-dashboard">
            <CardHeader className="card-header" color="warning" stats icon>
              <CardIcon className="cord-icon-rtl" color="warning">
                <Icon>flash_on</Icon>
              </CardIcon>

              <p
                className={`card-header-text ${classes.cardCategory}`}
              >{`קוט"ש יומי`}</p>
              <h3 className={`card-header-text card-header-title ${classes.cardTitle}`}>
                {/* {getNumber_K_M(consumptionData.totalConsumption)} קוט"ש */}
                {`לא ידוע`}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />ב 24 שעות
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="card-dashboard">
            <CardHeader className="card-header" color="success" stats icon>
              <CardIcon className="cord-icon-rtl" color="success">
                {/* <Store /> */}
                <Icon>timer</Icon>
              </CardIcon>
              <p className={`card-header-text ${classes.cardCategory}`}>
                {" "}
                שעות חיבור לעמדה
              </p>
              <h3 className={`card-header-text card-header-title ${classes.cardTitle}`}>
                {/* {getNumber_K_M(consumptionData.totalConsumptionTime)}
                &nbsp;שעות */}
                {`לא ידוע`}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />ב 24 שעות
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="card-dashboard">
            <CardHeader className="card-header" color="danger" stats icon>
              <CardIcon className="cord-icon-rtl" color="danger">
                <Icon>electric_car</Icon>
              </CardIcon>
              {/* <img src={electric_car_icon} alt="electric car icon" className="electric-car-icon"></img> */}
              <p className={`card-header-text ${classes.cardCategory}`}>
                כמות רכבים מחוברים לעמדה
              </p>
              <h3 className={`card-header-text card-header-title ${classes.cardTitle}`}>
                {" "}
                {/* {getNumber_K_M(consumptionData.totalChargedVehicles)} */}
                {`לא ידוע`}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />ב 24 שעות
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="card-dashboard">
            <CardHeader className="card-header" color="info" stats icon>
              <CardIcon className="cord-icon-rtl" color="info">
                <span className="material-icons">co2</span>
              </CardIcon>
              <p className={`card-header-text ${classes.cardCategory}`}>
                CO2 חיסכון
              </p>
              <h3 className={`card-header-text card-header-title ${classes.cardTitle} `}>
                {" "}
                {/* {getNumber_K_M(consumptionData.totalCO2Saved)} &nbsp;CO2 */}
                {`לא ידוע`}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />ב 24 שעות
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="card-dashboard">
            <CardHeader className="card-header" color="success" icon>
              <CardIcon className="cord-icon-rtl" color="success">
                <Language />
              </CardIcon>
              <h4 className={`card-operators-text ${classes.cardIconTitle}`}>
                מפעילים
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={5} className="operator-table">
                  <Table tableData={setTableData()} />
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <CellochargeSite dashboard={true} />
                  <Link
                    className="link-to-map-tab"
                    to={"/rtl/cellocharge-site"}
                  >
                    <ArrowCircleLeftOutlinedIcon />
                    <span className="span-text">
                      להצגת המפה במסך מלא לחץ כאן
                    </span>
                  </Link>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{display: 'none'}}>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={weeklyGraphData}
                type="Line"
                options={weeklyGraphData.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="רענן נתונים לזמן אמת"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    simple
                    color="info"
                    justIcon
                    onClick={() => onWeeklyDateChange(moment().format())}
                  >
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="בחר תאריך להצגה"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    simple
                    justIcon
                    onClick={() =>
                      setOpenWeeklyDatePicker(!openWeeklyDatePicker)
                    }
                  >
                    <Icon>date_range</Icon>
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>תצוגה שבועית</h4>
              {getWeeklyAverageGraph()}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                מעודכן לתאריך:&nbsp;
                {moment(weeklySelectedTime).format("DD-MM-YYYY")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader
              color="warning"
              className={`${classes.cardHeaderHover} yearly-graph-card-header`}
            >
              <ChartistGraph
                className="ct-chart-white-colors yearly-graph"
                data={yearlyGraphData}
                type="Bar"
                options={yearlyGraphData.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="רענן נתונים לזמן אמת"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    simple
                    color="info"
                    justIcon
                    onClick={() => onYearlyDateChange(moment().format())}
                  >
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="בחר תאריך להצגה"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    simple
                    justIcon
                    onClick={() =>
                      setOpenYearlyDatePicker(!openYearlyDatePicker)
                    }
                  >
                    {/* <Edit className={classes.underChartIcons} /> */}
                    <Icon>date_range</Icon>
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>תצוגה חודשית</h4>
              {getYearlyAverageGraph()}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                מעודכן לשנת:&nbsp;{moment(yearlySelectedTime).format("YYYY")}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="danger" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={dailyGraphData}
                type="Line"
                options={dailyGraphData.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="רענן נתונים לזמן אמת"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    simple
                    color="info"
                    justIcon
                    onClick={() => onHourlyTimeChange(moment().format())}
                  >
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="בחר תאריך להצגה"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="transparent"
                    simple
                    justIcon
                    onClick={() =>
                      setOpenHourlyTimePicker(!openHourlyTimePicker)
                    }
                  >
                    <Icon>date_range</Icon>
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>תצוגת זמן אמת</h4>
              {getHourlyAverageGraph()}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                מעודכן לתאריך:&nbsp;
                {moment(hourlySelectedTime).format("DD-MM-YYYY  HH")}:00
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {openWeeklyDatePicker && (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              onClose={() => setOpenWeeklyDatePicker(false)}
              maxDate={new Date()}
              // minDate={new Date("2018-03-01")}
              value={null}
              onChange={onWeeklyDateChange}
              open={openWeeklyDatePicker}
              InputProps={{
                disableUnderline: true
              }}
              emptyLabel=""
              label=""
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
      {openYearlyDatePicker && (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              openTo="year"
              maxDate={new Date()}
              // minDate={new Date("2018-03-01")}
              onClose={() => setOpenYearlyDatePicker(false)}
              value={null}
              views={["year", "month"]}
              onChange={onYearlyDateChange}
              open={openYearlyDatePicker}
              InputProps={{
                disableUnderline: true
              }}
              emptyLabel=""
              label=""
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
      {openHourlyTimePicker && (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              onClose={() => setOpenHourlyTimePicker(false)}
              maxDate={new Date()}
              // minDate={new Date("2018-03-01")}
              // views={["hours"]}
              // ampm={false}
              value={null}
              onChange={onHourlyTimeChange}
              open={openHourlyTimePicker}
              InputProps={{
                disableUnderline: true
              }}
              emptyLabel=""
              label=""
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
    </div>
  );
}
