/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter
  // useLocation
} from "react-router-dom";

import AuthLayout from "./layouts/Auth.js";
import RtlLayout from "./layouts/RTL.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import Dashboard from "views/Dashboard/Dashboard.js";


const hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter basename="/PortalDashboard/frontend" history={hist}>
    <Switch>    
      <Route path="/rtl" component={RtlLayout} />
      {/* <Route path= "/auth" component={AuthLayout} /> */}

      <Route path="/auth/login-page" component={AuthLayout} />      

      {/* {getRoutes(routes)} */}
      {/* <Route path="./PortalDashboard/frontend" component={AuthLayout} />  */}
      {/* <Route path="/admin" component={RtlLayout} /> */}
      {/* <Redirect from="/" to="/PortalDashboard/frontEnd/" /> */}
      <Redirect from="/" to="./auth/login-page" />
      <Redirect from="*" to="./auth/login-page" />      
      {/* <Redirect from="*" to="/PortalDashboard/frontend/auth/login-page" /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
