import { webAPIUrl } from './AppSettings';
import * as configData from "../appConfig.json";

// export interface HttpRequest<REQB> {
//   path: string;
// }

// export interface HttpResponse<RESB> {
//   ok: boolean;
//   body?: RESB;
// }

export async function http(config) {
  let request;

  request = new Request(`${configData.SERVER_URL + "api"}${config.path}`);
  const response = await fetch(request);
  if (response.ok) {
    const body = await response.json();
    return { ok: response.ok, body };
  } else {
    logError(request, response);
    return { ok: response.ok };
  }
}

async function logError(request, response) {
  const contentType = response.headers.get('content_type');
  let body;
  if (contentType && contentType.indexOf('application/json') !== -1) {
    body = await response.json();
  } else {
    body = await response.text();
  }
  console.error(`Error Requesting ${request.method} ${request.url}`, body);
}
