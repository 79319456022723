// import Buttons from "views/Components/Buttons.js";
// import Calendar from "views/Calendar/Calendar.js";
// import Charts from "views/Charts/Charts.js";
import Dashboard from "./views/Dashboard/Dashboard.js";
// import ErrorPage from "views/Pages/ErrorPage.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import FullScreenMap from "views/Maps/FullScreenMap.js";
// import GoogleMaps from "views/Maps/GoogleMaps.js";
// import GridSystem from "views/Components/GridSystem.js";
// import Icons from "views/Components/Icons.js";
// import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "./views/Pages/LoginPage.js";
// import Notifications from "views/Components/Notifications.js";
// import Panels from "views/Components/Panels.js";
// import PricingPage from "views/Pages/PricingPage.js";
// import RTLSupport from "views/Pages/RTLSupport.js";
// import ReactTables from "views/Tables/ReactTables.js";
import OperatorsTables from "./views/Tables/Operators";
import StationsTables from "./views/Tables/Stations";
import ViewsReportTables from "./views/Tables/ViewsReport";
import UsersTables from "./views/Tables/Users";
import PermissionsTables from "./views/Tables/Permissions";
import CellochargeSite from "./views/Maps/CellochargeSite";
import LocalizationTables from "./views/Tables/Localization";
// import RegisterPage from "views/Pages/RegisterPage.js";
// import RegularForms from "views/Forms/RegularForms.js";
// import RegularTables from "views/Tables/RegularTables.js";
// import SweetAlert from "views/Components/SweetAlert.js";
// import TimelinePage from "views/Pages/Timeline.js";
// import Typography from "views/Components/Typography.js";
// import UserProfile from "views/Pages/UserProfile.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Widgets from "views/Widgets/Widgets.js";
// import Wizard from "views/Forms/Wizard.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StorageIcon from '@mui/icons-material/Storage';
import Settings from "@material-ui/icons/Settings";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "לוח מחוונים",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/rtl"
  },
  {
    path: "/cellocharge-site",
    name: "CellochargeSite",
    rtlName: "אתר רספונסיבי",
    icon: LocationOnIcon,
    component: CellochargeSite,
    layout: "/rtl"
  },

  {
    collapse: true,
    name: "Operating Tools",
    rtlName: "כלי תפעול",
    icon: WidgetsIcon,
    state: "operatingCollapse",
    views: [

      {
        path: "/operators",
        name: "Operators",
        rtlName: "מפעילים",
        mini: "OP",
        rtlMini: "מ",
        component: OperatorsTables,
        layout: "/rtl"
      },
      {
        path: "/stations",
        name: "Stations",
        rtlName: "תחנות",
        mini: "ST",
        rtlMini: "ת",
        component: StationsTables,
        layout: "/rtl"
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "התחברות",
        mini: "L",
        rtlMini: "כנ",
        component: LoginPage,
        layout: "/auth",
        redirect: true
      },
    ]
  },
  {
    collapse: true,
    name: "Manager Tools",
    rtlName: "כלי ניהול",
    icon: StorageIcon,
    state: "ManagerTools",
    views: [
      {
        path: "/views-report",
        name: "Views report",
        rtlName: "דוח צפיות",
        mini: "VR",
        rtlMini: "ד",
        component: ViewsReportTables,
        layout: "/rtl"
      },

      //UsersTables
    ]
  },
  {
    collapse: true,
    name: "system Tools",
    rtlName: "כלי מערכת",
    icon: Settings,
    state: "systemCollapse",
    views: [

      {
        path: "/localization",
        name: "localization",
        rtlName: "לוקליזציה",
        mini: "LO",
        rtlMini: "ל",
        component: LocalizationTables,
        layout: "/rtl"
      },
      {
        path: "/users-tables",
        name: "Users tables",
        rtlName: "משתמשים",
        mini: "VR",
        rtlMini: "מ",
        component: UsersTables,
        layout: "/rtl"
      },
      {
        path: "/permissions-tables",
        name: "Permissions",
        rtlName: "הרשאות",
        mini: "pe",
        rtlMini: "ה",
        component: PermissionsTables,
        layout: "/rtl"
      },
      {
        path: "*",
        name: "Dashboard",
        rtlName: "לוח מחוונים",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/rtl"
      }

      //CellochargeSite
    ]
  }
  // {
  //   collapse: true,
  //   name: "Pages",
  //   rtlName: "דפים",
  //   icon: Image,
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/pricing-page",
  //       name: "Pricing Page",
  //       rtlName: "דף תמחור",
  //       mini: "PP",
  //       rtlMini: "דף",
  //       component: PricingPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/rtl-support-page",
  //       name: "RTL Support",
  //       rtlName: "RTL כיוון",
  //       mini: "RS",
  //       rtlMini: "RL",
  //       component: RTLSupport,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/timeline-page",
  //       name: "Timeline Page",
  //       rtlName: "ציר הזמן",
  //       mini: "T",
  //       rtlMini: "זמן",
  //       component: TimelinePage,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/login-page",
  //       name: "Login Page",
  //       rtlName: "התחברות",
  //       mini: "L",
  //       rtlMini: "כנ",
  //       component: LoginPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/register-page",
  //       name: "Register Page",
  //       rtlName: "הרשמה",
  //       mini: "R",
  //       rtlMini: "הר",
  //       component: RegisterPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/lock-screen-page",
  //       name: "Lock Screen Page",
  //       rtlName: "התנתקות",
  //       mini: "LS",
  //       rtlMini: "הת",
  //       component: LockScreenPage,
  //       layout: "/auth"
  //     },
  //     {
  //       path: "/user-page",
  //       name: "User Profile",
  //       rtlName: "פרופיל משתמש",
  //       mini: "UP",
  //       rtlMini: "פר",
  //       component: UserProfile,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/error-page",
  //       name: "Error Page",
  //       rtlName: "עמוד 404",
  //       mini: "E",
  //       rtlMini: "404",
  //       component: ErrorPage,
  //       layout: "/auth"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   rtlName: "קומפוננטות",
  //   icon: Apps,
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Multi Level Collapse",
  //       rtlName: "רמות מרובות",
  //       mini: "MC",
  //       rtlMini: "רמ",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           rtlName: "כפתורים",
  //           mini: "B",
  //           rtlMini: "כפ",
  //           component: Buttons,
  //           layout: "/rtl"
  //         }
  //       ]
  //     },
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       rtlName: "כפתורים",
  //       mini: "B",
  //       rtlMini: "כפ",
  //       component: Buttons,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       rtlName: "התאמה אישית",
  //       mini: "GS",
  //       rtlMini: "הת",
  //       component: GridSystem,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       rtlName: "פאנלים",
  //       mini: "P",
  //       rtlMini: "פא",
  //       component: Panels,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       rtlName: "התראות מתוקות ",
  //       mini: "SA",
  //       rtlMini: "הת",
  //       component: SweetAlert,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       rtlName: "הודעות התראות",
  //       mini: "N",
  //       rtlMini: "דע",
  //       component: Notifications,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       rtlName: "אייקונים",
  //       mini: "I",
  //       rtlMini: "אי",
  //       component: Icons,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       rtlName: "טופוגרפיה",
  //       mini: "T",
  //       rtlMini: "טו",
  //       component: Typography,
  //       layout: "/rtl"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   rtlName: "טפסים",
  //   icon: "content_paste",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       rtlName: "טפסים רגילים",
  //       mini: "RF",
  //       rtlMini: "טר",
  //       component: RegularForms,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       rtlName: "טפסים מרובים ",
  //       mini: "EF",
  //       rtlMini: "טמ",
  //       component: ExtendedForms,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       rtlName: "טפסי אימות",
  //       mini: "VF",
  //       rtlMini: "טא",
  //       component: ValidationForms,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       rtlName: "אשף",
  //       mini: "W",
  //       rtlMini: "ث",
  //       component: Wizard,
  //       layout: "/rtl"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   rtlName: "טבלאות",
  //   icon: GridOn,
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       rtlName: "טבלאות רגילות",
  //       mini: "RT",
  //       rtlMini: "טברב",
  //       component: RegularTables,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       rtlName: "טבלאות מורחבות",
  //       mini: "ET",
  //       rtlMini: "טמ",
  //       component: ExtendedTables,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       rtlName: "טבלאות REACT",
  //       mini: "RT",
  //       rtlMini: "טR",
  //       component: ReactTables,
  //       layout: "/rtl"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   rtlName: "מפות",
  //   icon: Place,
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       rtlName: "מפות גוגל",
  //       mini: "GM",
  //       rtlMini: "מג",
  //       component: GoogleMaps,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       name: "Full Screen Map",
  //       rtlName: "מפה מסך מלא",
  //       mini: "FSM",
  //       rtlMini: "מא",
  //       component: FullScreenMap,
  //       layout: "/rtl"
  //     },
  //     {
  //       path: "/vector-maps",
  //       name: "Vector Map",
  //       rtlName: "מפה וקטורית",
  //       mini: "VM",
  //       rtlMini: "מו",
  //       component: VectorMap,
  //       layout: "/rtl"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "וידג'טים",
  //   icon: WidgetsIcon,
  //   component: Widgets,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "תרשימים",
  //   icon: Timeline,
  //   component: Charts,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "לוח שנה",
  //   icon: DateRange,
  //   component: Calendar,
  //   layout: "/rtl"
  // }
];
export default dashRoutes;
