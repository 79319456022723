import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import './steps.scss';
import { ENumPages } from '../../Pages/EnumPages'

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
  }

  isValidated() {
    return true;
  }

  getOpeningPageName = () => {
    const page = ENumPages.find(ePage => ePage.id === this.props.openingPageID);
    return (page?.pageDescription)
  }

  getSelectedPage = () => {
    const selectedPage = [];
    this.props.pagesSelected.forEach(pageId => {
      const page = ENumPages.find(ePage => ePage.id === pageId);
      if (page) {
        selectedPage.push(' ' + page.pageDescription)
      }
    });
    return (selectedPage.toString())
  }

  render() {
    console.log('props', this.props)
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>סיכום ואישור</h4>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <div className='permission-name rtl'>שם הרשאה: {this.props.groupName}.</div>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <div className='permission-name rtl'>דף פתיחה: {this.getOpeningPageName()}.</div>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <div className='permission-name rtl'>דפים הניתנים לצפיה: {this.getSelectedPage()}.</div>
        </GridItem>
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step3);
