import React from "react";
import PropTypes from "prop-types";
import Pagination from "./Pagination";

const defaultButton = (props) => <button {...props}>{props.children}</button>;
const PREV = "הקודם";
const NEXT = 'הבא';

export default class MyPagination extends React.Component {
    constructor(props) {
        super();
        this.changePage = this.changePage.bind(this);

        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages)
        };
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
        previousText: PropTypes.string,
        nextText: PropTypes.string
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            let visiblePages = this.getVisiblePages(null, nextProps.pages);
            this.setState({ visiblePages });
        }

        this.changePage(nextProps.page + 1);
    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter((page) => (page <= totalPages || page === PREV || page === NEXT));
    };

    getVisiblePages = (page, total) => {
        let visiblePages = [];
        if (total < 7) {
            visiblePages = this.filterPages([PREV, 1, 2, 3, 4, 5, 6, NEXT], total);
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                visiblePages = [PREV, 1, page - 1, page, page + 1, total, NEXT];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                visiblePages = [PREV, 1, total - 3, total - 2, total - 1, total, NEXT];
            } else {
                visiblePages = [PREV, 1, 2, 3, 4, 5, total, NEXT];
            }
        }
        return (visiblePages)
    };

    changePage(page) {
        if (page === PREV) {
            if (this.props.page < 1) return;
            page = this.props.page
        }
        if (page === NEXT) {
            page = this.props.page + 2;
            if (page > this.props.pages) return;
        }
        const activePage = this.props.page + 1;
        if (page === activePage) {
            return;
        }
        let visiblePages = this.getVisiblePages(page, this.props.pages);
        visiblePages = this.filterPages(visiblePages, this.props.pages);
        this.setState({ visiblePages });
        this.props.onPageChange(page - 1);
    }

    render() {
        const { PageButtonComponent = defaultButton } = this.props;
        const { visiblePages } = this.state;
        const activePage = this.props.page + 1;
        return (
            <div className="Table__pagination">
                <div className="Table__visiblePagesWrapper">
                    <div className='pagination-table'>
                        <Pagination
                            pages={visiblePages}
                            color="primary"
                            clickPagination={this.changePage}
                            activePage={activePage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
