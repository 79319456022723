import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { useTable, useFilters, setFilter, useGlobalFilter, useAsyncDebounce } from 'react-table'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import EvStation from "@material-ui/icons/EvStation";
import Settings from "@material-ui/icons/Settings";
import PersonAdd from "@material-ui/icons/PersonAdd";
//PersonAddAlt
import Close from "@material-ui/icons/Close";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
// Print
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { GetStationsData } from "../../Data/StationsData";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";
import MyPagination from "components/Pagination/MyPagination";
import stylesValidateForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Edit from "@material-ui/icons/Edit";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { CSVDownload } from "react-csv";
import { useReactToPrint } from "react-to-print";
import TablesLogic from "./TablesLogic.js";

// import { dataTable } from "variables/general.js";

import * as configData from "../../appConfig.json";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import "./Operators.scss";
import axios from "axios";
import { Hidden } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import { style } from "@mui/system";
import { stat } from "fs";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,            
    },
  },
};

const useStyles = makeStyles(styles);
const useStylesValidateForm = makeStyles(stylesValidateForm);
const useStylesSweetAlertStyle = makeStyles(sweetAlertStyle);
const useStylesButtonsStyle = makeStyles(buttonsStyle);

export default function StationsTables() {
  const [stationsTable, setStationsTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [alertShow, setAlert] = useState(false);
  const [alertShowDelete, setAlertDelete] = useState(false);
  const [alertEditStation, setAlertEditStation] = useState(false);
  const [successEditStation, setSuccessEditStation] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(0);

  const [stationName, setStationName] = useState("");
  const [stationNameState, setStationNameState] = useState("");
  const [stationAddress, setStationAddress] = useState("");
  const [stationAddressState, setStationAddressState] = useState("");
  const [plugs, setPlugs] = useState("");
  const [plugsState, setPlugsState] = useState("");
  const [connectorType, setConnectorType] = useState("");
  const [connectorTypeState, setConnectorTypeState] = useState("");
  const [averagePrice, setAveragePrice] = useState("");
  const [averagePriceState, setAveragePriceState] = useState("");
  const [stationHide, setStationHide] = useState(true);
  //

  const [operatorName, setOperatorName] = useState("");
  const [operatorNameState, setOperatorNameState] = useState("");
  const [stationToEdit, setStationToEdit] = useState(null);
  const [exportToCsvState, setExportToCsvState] = useState(false);
  const [printTableState, setPrintTableState] = useState(false);
  const [enableChangesButtonState, setSaveChangesButtonState] = useState(false);
  const [checkedItemsList, setcheckedItemsListState] = useState([]);
  const [filteredDataLength, setFilteredDataLength] = useState(0);


  const tablesLogic = new TablesLogic();  
  
  onwheel = () => setShowSettings(false);

  const getStationsDataFromServer = () => {
    const response = GetStationsData()
      .then(response => {
        console.log("GetStationsData", response);
        setStationsTable(response);
        setLoadingTable(false);
        setCurrentPagination(1);  
        setFilteredDataLength(response.length)              
      })
      .catch(err => {
        setLoadingTable(false);
        console.error(err);
      });
  };

  useEffect(() => {
    getStationsDataFromServer();
  }, []);  

  useEffect(() => {
    if (stationToEdit) {
      setAlertEditStation(true);
    }
  }, [stationToEdit]);

  useEffect(() => {
    setExportToCsvState(false);
  }, [exportToCsvState]);

  useEffect(() => {
    if (printTableState) handlePrint();
  }, [printTableState]);

  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };  

  const verifyUserNumber = value => {
    return value.length > 0;
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const addClick = () => {
    let closePopup = true;

    if (stationNameState === "") {
      closePopup = false;
      setStationNameState("error");
    }
    if (stationAddressState === "") {
      closePopup = false;
      setStationAddressState("error");
    }
    if (plugsState === "") {
      closePopup = false;
      setPlugsState("error");
    }
    if (operatorNameState === "") {
      closePopup = false;
      setOperatorNameState("error");
    }

    if (closePopup) {
      setAlert(false);
    }
  };


  const successDelete = obj => {
    const newStationsTable = stationsTable.filter(
      station => station.connectorID != obj.num
    );
    setStationsTable(newStationsTable);
    setAlertDelete(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="מחיקה בוצעה בהצלחה!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          classesSweetAlert.button + " " + classesSweetAlert.success
        }
      >
        מחקת בהצלחה את תחנת {obj.name}.
      </SweetAlert>
    );
  };
  const cancelDelete = () => {
    setAlertDelete(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="ביטול"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          classesSweetAlert.button + " " + classesSweetAlert.success
        }
      >
        המחיקה בוטלה!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlertDelete(null);
  };

  const warningWithConfirmAndCancelMessage = obj => {
    setAlertDelete(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="האם אתה בטוח?"
        onConfirm={() => successDelete(obj)}
        onCancel={() => cancelDelete()}
        confirmBtnCssClass={
          classesSweetAlert.button + " " + classesSweetAlert.success
        }
        cancelBtnCssClass={
          classesSweetAlert.button + " " + classesSweetAlert.danger
        }
        confirmBtnText="כן, אני רוצה למחק!"
        cancelBtnText="ביטול"
        showCancel
      >
        ביקשת למחוק את תחנת {obj.name} אנא וודא שאתה מעוניין בכך!
      </SweetAlert>
    );
  };

  const exportToCsv = () => {
    if (!exportToCsvState) return;    
    const headers = [
      // { label: "מספר מחבר", key: "num" },
      // { label: "מספר תחנה", key: "stationID" },
      { label: "שם התחנה", key: "name" },
      { label: "כתובת", key: "address" },
      { label: "עיר", key: "city" },
      { label: "קו רוחב", key: "lat" },
      { label: "קו אורך", key: "lng" },
      { label: "סטטוס תחנה", key: "stationStatus" },
      { label: "מפעיל", key: "operator" },
      { label: "מחבר", key: "plugs" },
      { label: "סטטוס מחבר", key: "stationStatus" },
      { label: "הספק", key: "power" },
      { label: "מהיר/איטי", key: "type" },      
      // { label: "מוסתר", key: "hide" },
      // { label: "תאריך יצירה", key: "settingsUp" },
      //{ label: "תאריך עדכון", key: "modifyDate" },
      { label: "מחיר", key: "price" },
    ];
    const csvData = stationsTable.map(prop => {      
      return {
        num: prop.connectorID,
        stationID: prop.stationID,
        name: prop.station_Name.replaceAll(',', ' '),
        address: prop.station_StreetName.replaceAll(',', ' '),
        city: prop.city,
        status: getStatusName(prop.status),
        stationStatus: getStatusName(prop.stationStatus),
        power: prop.power,
        plugs: getConnectorTypes(prop.connectorType),
        operator: prop.providerName,
        type: prop.type == 1 ? "מהיר" : "איטי",
        price: typeof(prop.price) == 'string' ?  prop.price.replace(/(?:\r\n|\r|\n|,)/g, ' ') : '',
        lat: prop.lat,
        lng: prop.long,
        hide: prop.hide,
        settingUp: prop.settingUp,
        //modifyDate: prop.modifyDate
      };
    });
    console.log("csvData", csvData);
    return (
      <CSVDownload
        filename={"operator data.csv"}
        headers={headers}
        data={csvData}
      />
    );
  };

  const editStationClick = () => {        

    const URL = configData.SERVER_URL + "api/station/update/";
    
    console.log(
      stationName,
      stationAddress,
      plugs,
      operatorName,
      connectorType,
      averagePrice,
      stationToEdit
    );
    const JSON_data = {
      id: stationToEdit.stationID,      
      station_Name: stationName,
      Address: stationAddress,
      hide: stationHide
    };
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertEditStation(false);
        setSuccessAlert(true);
        setSuccessEditStation(true);
        setSaveChangesButtonState(false);
        getStationsDataFromServer();

      })
      .catch(err => {
        console.error(err);
        setAlertEditStation(false);
        setSuccessAlert(true);
        setSuccessEditStation(false);
        setSaveChangesButtonState(false);
      });
  };

  const getStatusClassName = status => {
    switch (status) {
      case 0:
        return "unknown";
      case "פעיל":
        return "active";
      case "תפוס":
        return "partial";
      case "לא פעיל":
        return "inactive";              
      default:
        return "unknown";
    }
  };

  const getStatusName = status => {
    switch (status) {
      case 1:
        return "פעיל";
      case 2:
        return "תפוס";
      case 3:
        return "לא פעיל";
      case 4:
        return "לא פעיל";
      default:
        return "לא ידוע";
    }
  }

  const handleChange = (val) => {
    setSaveChangesButtonState(true);
  }
  

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    onAfterPrint: () => {
      setPrintTableState(false);
    }
  });

  const getDefaultPageSize = () => {
    if (printTableState || tablesLogic.DEFAULT_ITEMS_PAGE > data.length)
      return data.length;
    return tablesLogic.DEFAULT_ITEMS_PAGE;
  };

  const getConnectorTypes = connType => {
    return connType
      .split(",")
      .map(item => item)
      .filter((value, index, self) => self.indexOf(value) === index).join(', ');
  };

  const data = stationsTable.map((prop, key) => {
    const hideClassName = !prop.hide ? "green-active" : "red-active";
    return {
      id: key,
      num: prop.ConnectorID,
      name: prop.station_Name,
      address: prop.station_StreetName,
      city: prop.city,
      plugs: getConnectorTypes(prop.connectorType),
      operator: prop.providerName,
      type: prop.type == 0 ? "איטי" : "מהיר",
      price: prop.price,
      power: prop.power.toFixed(),
      settingUp: prop.settingUp,
      //modifyDate: prop.modifyDate,
      stationStatus: getStatusName(prop.stationStatus),
      status: getStatusName(prop.status),
      actions: (
        <div className="actions-right edit-button">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let obj = data.find(o => o.id === key);
              console.log(obj);
              setStationToEdit(obj);
              setStationName(obj.name);
              setStationAddress(obj.address);
              setPlugs(obj.plugs);
              setOperatorName(obj.operator);
              setAveragePrice(obj.price);
              setStationHide(obj.hide);
            }}
            color="primary"
            className="edit"
          >
            <Edit />
          </Button>{" "}
          {/* <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                            let obj = data.find(o => o.id === key);
                            console.log(obj);
                            warningWithConfirmAndCancelMessage(obj)

                        }}
                        color="danger"
                        className="remove"
                    >
                        <Close />
                    </Button>{" "} */}
        </div>
      )
    };
  });
  const classes = useStyles();
  const classesValidateForm = useStylesValidateForm();
  const classesSweetAlert = useStylesSweetAlertStyle();
  const classesButtonsStyle = useStylesButtonsStyle();  

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="operator-card-table">
            <CardHeader className="operator-header-table" color="primary" icon>
              <CardIcon className="operator-logo-table" color="primary">
                <EvStation />
              </CardIcon>
              <Button
                justIcon
                round
                color="primary"
                className="settings-operator-logo"
                onClick={() => setShowSettings(!showSettings)}
              >
                <Settings />
              </Button>
              <h4 className={`title-operator-table ${classes.cardIconTitle}`}>
                {" "}
                תחנות{" "}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable 
                className="card-table-screen -striped -highlight"
                // style={{ display: 'none' }}

                columns= {[
                  /*                  {
                    //Header: "מספר",
                   // accessor: "num",
                    sortable: false,
                    filterable: false
                   // style: { display: "none" }
                  }, */
                  {
                    Header: "שם התחנה",
                    accessor: "name",    
                                    
                  },
                  {
                    Header: "כתובת",
                    accessor: "address"
                  },
                  {
                    Header: "עיר",
                    accessor: "city",
                    Filter: SelectColumnFilter,                    
                    filter: 'equals',                      
                  },
                  {
                    Header: "סטטוס תחנה",
                    accessor: "stationStatus",
                    Cell: props => <div>
                    <span title={getStatusClassName(props.value)} className={getStatusClassName(props.value)}>
                    </span>
                    <span>
                      {props.value}
                    </span>
                  </div>,
                  Filter: SelectColumnFilter,                    
                  filter: 'equals'             
                  },               
                  {
                    Header: "מפעיל",
                    accessor: "operator",
                    Filter: SelectColumnFilter,                    
                    filter: 'equals',                      
                  },
                  {
                    Header: "מחבר",
                    accessor: "plugs",
                    Filter: SelectColumnFilter,                    
                    filter: 'equals',                    
                  },
                  {
                    Header: "סטטוס מחבר",
                    accessor: "status",
                    Cell: props => <div>
                    <span title={getStatusClassName(props.value)} className={getStatusClassName(props.value)}>
                    </span>
                    <span>
                      {props.value}
                    </span>
                  </div>,
                  Filter: SelectColumnFilter,                    
                  filter: 'equals'             
                  },                                
                  {
                    Header: "מחיר",
                    accessor: "price"
                  },
                  {
                    Header: "הספק",
                    accessor: "power",
                    filterable: false
                  },
                  {
                    Header: "מהיר/איטי",
                    accessor: "type",
                    Filter: SelectColumnFilter,                    
                    filter: 'equals',                      
                  },
                  // {
                  //   Header: "מוסתר",
                  //   accessor: "hideState"                                 
                  // },
                  // {
                  //   Header: "ת. יצירה",
                  //   accessor: "settingUp",
                  //   Cell: (d) => { return !!data[d.index].settingsUp ? moment(data[d.index].settingsUp).format('DD/MM/YYYY HH:mm'): null},
                  //   style: { direction: "ltr" }        
                  // },

                  // {
                  //   Header: "ת. עדכון",
                  //   accessor: "modifyDate",
                  //   Cell: (d) => { return !!data[d.index].modifyDate ? moment(data[d.index].modifyDate).format('DD/MM/YYYY HH:mm'): null},
                  //   style: { direction: "ltr" }
                  // },
                  // {
                  //   Header: "",
                  //   accessor: "actions",
                  //   sortable: false,
                  //   filterable: false
                  // }
                ]}
                useFilters={true}
                ref={componentRef}
                data={data}
                filterable={printTableState ? false : true}
                loading={loadingTable}
                PaginationComponent={MyPagination}
                showPagination={printTableState ? false : true}
                //onFilteredChanged={() => {setFilteredDataLength(useTable.rows.length)}}                
                defaultPageSize={getDefaultPageSize()}
                key={printTableState ? data.length : currentPagination}
                // className="-striped -highlight"                
                defaultFilterMethod={(filter, row) => {                      
                  const id = filter.pivotId || filter.id;                
                  if (row[id] !== null && row[id] !== undefined)     {
                  
                  if (typeof(filter.value) == "string") {
                  return row[id] !== null && row[id] !== undefined
                    ? String(row[id].toLowerCase()).search(
                        filter.value.toString().toLowerCase()
                      ) >= 0
                      ? true
                      : false
                    : true;                  
                  } else {                    

                     if (row[id] !== null && row[id] !== undefined) {
                      console.log(filter.value.findIndex(x => x.toLowerCase() == row[id].toLowerCase()), filter.value, row[id])
                        return filter.value.findIndex(x => x.toLowerCase() == row[id].toLowerCase()) >= 0 ? true : false                        
                     }
                    }
                  }
                 }
              }
              />
            </CardBody>
            <div className="card-footer">
            <CardFooter>
                <span>נמצאו <span className="result-count">{
                  filteredDataLength
                }</span> תוצאות</span>                
            </CardFooter>          
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      {showSettings && (
        <div
          className="wrapper-dropdown"
          onClick={() => setShowSettings(false)}
        >
          <div className="fixed-plugin fixed-plugin-rtl">
            <div id="fixedPluginClasses " className="dropdown show">
              <div className="dropdown-menu">
                {/* <div className="add-operator-div" onClick={() => { setAlert(true); setShowSettings(false) }}>
                                    <div className="add-operator-icon">
                                        <PersonAdd />   הוספת תחנה
                                    </div>
                                </div> */}
                <div
                  className="export-div"
                  onClick={() => {
                    setExportToCsvState(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="export-icon">
                    <GetApp className={classesButtonsStyle.icons} /> יצוא תחנות
                  </div>
                </div>

                <div
                  className="print-div"
                  onClick={() => {
                    setPrintTableState(true);
                    setShowSettings(false);
                  }}
                >
                  <span className="print-icon">
                    <Print className={classesButtonsStyle.icons} /> הדפסה
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertShow && (
        <div className="popup-operator">
          <SweetAlert
            style={{
              width: "80%",
              //  height: '80%', overflowY: 'auto',
              position: "absolute",
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => setAlert(false)}
            showConfirm={false}
            onCancel={() => setAlert(false)}
            // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
          >
            <div className="close-icon-popup" onClick={() => setAlert(false)}>
              <CloseIcon />
            </div>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <EvStation />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    הוספת תחנה
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      success={stationNameState === "success"}
                      error={stationNameState === "error"}
                      labelText="שם תחנה*"
                      id="stationName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setStationNameState("success");
                          } else {
                            setStationNameState("error");
                          }
                          setStationName(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={stationAddressState === "success"}
                      error={stationAddressState === "error"}
                      labelText="כתובת התחנה*"
                      id="stationAddress"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setStationAddressState("success");
                          } else {
                            setStationAddressState("error");
                          }
                          setStationAddress(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={plugsState === "success"}
                      error={plugsState === "error"}
                      labelText="שקעים*"
                      id="plugs"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setPlugsState("success");
                          } else {
                            setPlugsState("error");
                          }
                          setPlugs(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={operatorNameState === "success"}
                      error={operatorNameState === "error"}
                      labelText="שם המפעיל*"
                      id="operatorName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setOperatorNameState("success");
                          } else {
                            setOperatorNameState("error");
                          }
                          setOperatorName(event.target.value);
                        },
                        type: "string"
                      }}
                    />

                    <div className={classesValidateForm.formCategory}>
                      <small>*</small> שדות חובה
                    </div>

                    <Button
                      color="rose"
                      onClick={addClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {alertEditStation && (
        //   dailyKWH: prop.totalConsumptionTime,
        //   dailyVehicles: prop.totalChargedVehicles,
        //   dailyCO2: prop.totalCO2Saved,
        <div className="popup-operator">
          <SweetAlert
            style={{
              width: "80%",
              //  height: '80%', overflowY: 'auto',
              position: "absolute",
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => {
              setAlertEditStation(false);
              setSaveChangesButtonState(false);}}
            showConfirm={false}
            onCancel={() => {
              setAlertEditStation(false);
              setStationToEdit(null);
              setSaveChangesButtonState(false);
            }}

            // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
          >
            <div
              className="close-icon-popup"
              onClick={() => {setAlertEditStation(false);
                              setSaveChangesButtonState(false);}}
            >
              <CloseIcon />
            </div>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <EvStation />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    עריכת תחנה
                  </h4>
                </CardHeader>
                <CardBody>
                  <form onChange={handleChange}>
                    <CustomInput
                      success={stationNameState === "success"}
                      error={stationNameState === "error"}
                      labelText="שם תחנה*"
                      id="stationName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setStationNameState("success");
                          } else {
                            setStationNameState("error");
                          }
                          setStationName(event.target.value);
                        },
                        defaultValue: stationToEdit.name,
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={stationAddressState === "success"}
                      error={stationAddressState === "error"}
                      labelText="כתובת התחנה*"
                      id="stationAddress"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setStationAddressState("success");
                          } else {
                            setStationAddressState("error");
                          }
                          setStationAddress(event.target.value);
                        },
                        defaultValue: stationToEdit.address,
                        type: "string"
                      }}
                    />
                    {/* <div className="switch-trigger-div">
                      <span className="switch-label">הסתרת חניה: </span>
                      <Switch
                        checked={stationHide}
                        onChange={() => setStationHide(!stationHide)}
                        value="stationHide"
                        classes={{
                          switchBase: classesSweetAlert.switchBase,
                          checked: classesSweetAlert.switchChecked,
                          thumb: classesSweetAlert.switchIcon,
                          track: classesSweetAlert.switchBar
                        }}
                      />
                    </div> */}
                    {/* <CustomInput
                                            success={plugsState === "success"}
                                            error={plugsState === "error"}
                                            labelText="שקעים*"
                                            id="plugs"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    if (verifyUserNumber(event.target.value)) {
                                                        setPlugsState("success");
                                                    } else {
                                                        setPlugsState("error");
                                                    }
                                                    setPlugs(event.target.value);
                                                },
                                                defaultValue: stationToEdit.plugs,
                                                type: "string"
                                            }}
                                        />
                                        <CustomInput
                                            success={operatorNameState === "success"}
                                            error={operatorNameState === "error"}
                                            labelText="שם המפעיל*"
                                            id="operatorName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    if (verifyUserNumber(event.target.value)) {
                                                        setOperatorNameState("success");
                                                    } else {
                                                        setOperatorNameState("error");
                                                    }
                                                    setOperatorName(event.target.value);
                                                },
                                                defaultValue: stationToEdit.operator,
                                                type: "string"
                                            }}
                                        />

                                        <CustomInput
                                            success={connectorTypeState === "success"}
                                            error={connectorTypeState === "error"}
                                            id="connectorType"
                                            labelText='סוג עמדה'
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    if (verifyUserNumber(event.target.value)) {
                                                        setConnectorTypeState("success");
                                                    } else {
                                                        setConnectorTypeState("error");
                                                    }
                                                    setConnectorType(event.target.value);
                                                },
                                                defaultValue: stationToEdit.type,
                                                type: "string"

                                            }}
                                        />
                                        <CustomInput
                                            success={averagePriceState === "success"}
                                            error={averagePriceState === "error"}
                                            id='averagePrice'
                                            labelText='מחיר ממוצע'
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: event => {
                                                    if (verifyUserNumber(event.target.value)) {
                                                        setAveragePriceState("success");
                                                    } else {
                                                        setAveragePriceState("error");
                                                    }
                                                    setAveragePrice(event.target.value);
                                                },
                                                defaultValue: stationToEdit.price,
                                                type: "string"

                                            }}
                                        /> */}

                    <Button
                      disabled={!enableChangesButtonState}                    
                      id="btn-edit"                    
                      color="rose"
                      onClick={editStationClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {successAlert && (
        <SweetAlert
          success={successEditStation}
          error={!successEditStation}
          style={{ display: "block", marginTop: "-100px" }}
          title={successEditStation ? "המידע עודכן בהצלחה" : "הפעולה נכשלה"}
          onConfirm={() => setSuccessAlert(false)}
          onCancel={() => setSuccessAlert(false)}
          confirmBtnCssClass={
            classesSweetAlert.button + " " + classesSweetAlert.success
          }
        >
          {/* You clicked the button! */}
        </SweetAlert>
      )}
      {exportToCsv()}
      {alertShowDelete}
    </div>
  );
 
  function SelectColumnFilter(props) {
    const optionsSet = new Set();
    data.forEach(x=> {
      if(!optionsSet.has(x[props.column.id])){
        optionsSet.add(x[props.column.id]);   
      }
    }
    )
   
    // Render a multi-select box
    const arr = Array.from(optionsSet);
    return (
      <select className="filter-select" id="select-operators"
        value={props.filterValue}     
        onChange={e => {
          props.onChange(e.target.value)
          //console.log(e.target.value || undefined)          
        }}         
      >
        <option value="">הכל</option>
        {arr.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
      </select>
    )
  }

  function SelectColumnFilter2(props) {    
    const optionsSet = new Set();
    stationsTable.forEach(x=> {
      let d = x.connectorType.split(",");
      d.forEach(y => {
        if(!optionsSet.has(y)){
          optionsSet.add(y);
        }      
    })});
   
    // Render a multi-select box
    const arr = Array.from(optionsSet);
    return (
      <select className="filter-select" id="select-plugs"
        value={props.filterValue}     
        onChange={e => {
          props.onChange(e.target.value)
          
          // console.log(e.target.value || undefined)                    
        }}         
      >
        <option value="">הכל</option>
        {arr.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
      </select>
    )
  }

function SelectColumnFilter3(props) {    
  const optionsSet = new Set();
  data.forEach(x=> {
      if(!optionsSet.has(x[props.column.id])){
        optionsSet.add(x[props.column.id]);   
      }
    }
  )   
   
  // Render a multi-select box  
  const arr = ['הכל'].concat(Array.from(optionsSet));
  
  // if(!checkedItemsList.has(props.column.id)){
  //   checkedItemsList.set(props.column.id, []);
  // }
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={checkedItemsList}
          onChange={e => {
            const {
              target: { value },
            } = e;
            setcheckedItemsListState(
              //   // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
              );                 
              return props.onChange(e.target.value)           
          }}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {arr.map((option, i) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={checkedItemsList.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );    
}
}