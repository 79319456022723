import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { GetUsersData, LogOnUserName } from "../../Data/UsersData";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import "./LoginPage.scss";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { ENumPages } from "./EnumPages";
import ValidateFields from "./ValidateFields";
import * as config from '../../appConfig.json';

const useStyles = makeStyles(styles);
const useValidationFormsStyle = makeStyles(validationFormsStyle);
const validateFields = new ValidateFields();

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [userName, setUserName] = React.useState("");
  const [userNameState, setUserNameState] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordState, setPasswordState] = React.useState("");
  const [rememberMi, setRememberMi] = React.useState(false);
  const [redirectPage, setRedirectPage] = React.useState(false);
  const [usersTable, setUsersTable] = React.useState([]);
  const [loginFailed, setLoginFailed] = React.useState({status: false, msg: ""});
  const [loginFailedMessage, setLoginFailedMessage] = React.useState("");

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const classesValidationFormsStyle = useValidationFormsStyle();

  useEffect(() => {
    if (userName.length > 0) {
      // const userValidate = validateFields.userNameValidate(userName)
      //   ? "success"
      //   : "error";
      //setUserNameState(userValidate);
      setUserNameState("success");
    }
  }, [userName]);

  useEffect(() => {
    if (password.length > 0) {
      // const passwordValidate = validateFields.passwordValidate(password)
      //   ? "success"
      //   : "error";
      ///setPasswordState(passwordValidate);
      setPasswordState("success");
    }
  }, [password]);

  useEffect(() => {
    if (loginFailed.status) {
      setLoginFailedMessage(loginFailed.msg);      
    }
  });

  const getUsersDataFromServer = () => {
    const response = GetUsersData()
      .then(response => {
        console.log("users", response[0]);
        setUsersTable(response.data[0]);
      })
      .catch(err => {
        console.error(err);
      });
  };

 
  const sendToServer = () => {
    setLoginFailed(false);
    if (userNameState !== "success" || passwordState !== "success") return;
    //const URL = "https://cellocharge.com/PortalDashboard/api/Login/";
     const URL = config.SERVER_URL + "api/Login/";
    const JSON_data = {
      UserName: userName,
      Password: password,
      RememberMe: rememberMi
    };
    console.log("JSON_data", JSON_data);
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        getOpeningPage(response.data.id, response.data.openingPageID);
        //getUsersDataFromServer();
        sessionStorage.setItem("LogOnUserName", userName);
      })
      .catch(err => {
        let msg = "התחברות נכשלה";
        if (err.response && err.response.status == 400) {
          msg = "שם המשתמש ו/או הססמא אינם תקינים";
        }
        console.log(err);
        console.error(err);
        setLoginFailed({status: true, msg: msg});
      });
  };

  const getOpeningPage = (accessGroupId, openingPageID) => {
    // const openingPageURL =
    //   "https://cellocharge.com/portaldashboard/api/MenuCategories/AccessGroup/" +
    //   accessGroupId;
    const openingPageURL = config.SERVER_URL + "api/MenuCategories/AccessGroup/" +
       accessGroupId;
    axios
      .get(openingPageURL)
      .then(response => {
        console.info("operators.data", response.data);
        sessionStorage.setItem(
          "accessGroupPermissions",
          JSON.stringify(response.data)
        );
        const page = ENumPages.find(item => item.id === openingPageID);
        if (page) {
          setRedirectPage(page.value);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getUserName = id => {
    alert(usersTable);
    const user = usersTable.find(user => user.email === id);
    return user?.userName;
  };

  if (redirectPage) {
    return (
      <Redirect
        to={{
          pathname: "/rtl/" + redirectPage
        }}
      />
    );
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form className="login-form">
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>התחברות</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={userNameState === "success"}
                  error={userNameState === "error"}
                  labelText="שם משתמש"
                  // helperText={
                  //   userNameState === "error"
                  //     ? "שם משתמש לא חוקי"
                  //     : ""
                  // }
                  id="loginUserName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: event => {
                      setUserName(event.target.value);
                    }
                  }}
                />
                <CustomInput
                  success={passwordState === "success"}
                  error={passwordState === "error"}
                  labelText="סיסמא"
                  id="userPassword"
                  // helperText={
                  //   passwordState === "error"
                  //     ? "סיסמא חייבת להכיל לפחות שש תווים, הסיסמא חייבת להכיל לפחות: אות גדולה, אות קטנה, מספר, ותו מיוחד"
                  //     : ""
                  // }
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    onChange: event => {
                      setPassword(event.target.value);
                    },
                    type: "password"
                  }}
                />
                   
                <label className="sign-in-failed">{loginFailedMessage}</label>
              </CardBody>
              <FormControlLabel
                className="form-control-label-remember-mi"
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={event => {
                      setRememberMi(event.target.checked);
                    }}
                    checkedIcon={
                      <Check
                        className={classesValidationFormsStyle.checkedIcon}
                      />
                    }
                    icon={
                      <Check
                        className={classesValidationFormsStyle.uncheckedIcon}
                      />
                    }
                    classes={{
                      checked: classesValidationFormsStyle.checked,
                      root: classesValidationFormsStyle.checkRoot
                    }}
                  />
                }
                // classes={{
                //   label:
                //     classes.label +
                //     (registerCheckboxState === "error"
                //       ? " " + classes.labelError
                //       : "")
                // }}
                label="זכור אותי"
              />
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={sendToServer}
                >
                  שלח
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <div className="developed-by-cellopark">@ Developed by Cellopark</div>
    </div>
  );
}
