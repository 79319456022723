import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import Settings from "@material-ui/icons/Settings";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
//PersonAddAlt
import Edit from "@material-ui/icons/Edit";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { GetOperationData } from "../../Data/OperatorsDate";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";
import stylesValidateForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { CSVDownload } from "react-csv";
import { useReactToPrint } from "react-to-print";
import TablesLogic from "./TablesLogic.js";
import MyPagination from "components/Pagination/MyPagination";
import axios from "axios";
import moment from "moment";
import ValidateFields from "views/Pages/ValidateFields";
import afkon from "../../assets/Providers/afkon.png";
import charge from "../../assets/Providers/charge.png";
import greenspot from "../../assets/Providers/greenspot.png";
import sonol from "../../assets/Providers/sonol.png";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import "./Operators.scss";
import CardFooter from "components/Card/CardFooter";
import * as configData from '../../appConfig.json';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const il_flag = require("assets/img/flags/IL.png");
const defaultOperator = require("assets/img/defaultOperator.png");

const useStyles = makeStyles(styles);
const useStylesValidateForm = makeStyles(stylesValidateForm);
const useStylesSweetAlertStyle = makeStyles(sweetAlertStyle);
const useStylesButtonsStyle = makeStyles(buttonsStyle);
const validateFields = new ValidateFields();

export default function OperatorsTables() {
  
  const [operatorsTable, setOperatorsTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [alertShowAddOperator, setAlertShowAddOperator] = useState(false);
  const [alertEditOperator, setAlertEditOperator] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successEditOperator, setSuccessEditOperator] = useState(false);
  const [userActive, setUserActive] = useState(true);

  const [operatorName, setOperatorName] = useState("");
  const [operatorNameState, setOperatorNameState] = useState("");
  const [operatorAddress, setOperatorAddress] = useState("");
  const [operatorAddressState, setOperatorAddressState] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactFullNameState, setContactFullNameState] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberState, setPhoneNumberState] = useState("");
  const [operatorToEdit, setOperatorToEdit] = useState(null);
  const [exportToCsvState, setExportToCsvState] = useState(false);
  const [printTableState, setPrintTableState] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(0);

  const classes = useStyles();
  const classesValidateForm = useStylesValidateForm();
  const classesSweetAlert = useStylesSweetAlertStyle();
  const classesButtonsStyle = useStylesButtonsStyle();
  const tablesLogic = new TablesLogic();

  const getOperatorsDataFromServer = () => {
    const response = GetOperationData()
      .then(response => {
        console.log("operatorsTable", response);
        setOperatorsTable(response);
        setLoadingTable(false);
        setCurrentPagination(1);
      })
      .catch(err => {
        setLoadingTable(false);
        console.error(err);
      });
  };

  useEffect(() => {
    getOperatorsDataFromServer();
  }, []);
  
    window.onscroll = () => setShowSettings(false);  

  useEffect(() => {
    if (!alertShowAddOperator) {
      setOperatorName("");
      setOperatorNameState("");
      setOperatorAddress("");
      setOperatorAddressState("");
      setContactFullName("");
      setContactFullNameState("");
      setPhoneNumber("");
      setPhoneNumberState("");
      setOperatorToEdit(null);
    }
  }, [alertShowAddOperator]);

  useEffect(() => {
    if (!alertEditOperator) {
      setOperatorName("");
      setOperatorNameState("");
      setOperatorAddress("");
      setOperatorAddressState("");
      setContactFullName("");
      setContactFullNameState("");
      setPhoneNumber("");
      setPhoneNumberState("");
      setOperatorToEdit(null);
    }
  }, [alertEditOperator]);

  useEffect(() => {
    if (operatorToEdit) {
      setAlertEditOperator(true);
    }
  }, [operatorToEdit]);

  useEffect(() => {
    if (operatorName.length > 0) {
      const operatorNameValidate = validateFields.nameValidate(operatorName)
        ? "success"
        : "error";
      setOperatorNameState(operatorNameValidate);
    }
  }, [operatorName]);

  useEffect(() => {
    if (operatorAddress.length > 0) {
      const operatorAddressValidate = validateFields.nameValidate(
        operatorAddress
      )
        ? "success"
        : "error";
      setOperatorAddressState(operatorAddressValidate);
    }
  }, [operatorAddress]);

  useEffect(() => {
    if (contactFullName.length > 0) {
      const contactFullNameValidate = validateFields.nameValidate(
        contactFullName
      )
        ? "success"
        : "error";
      setContactFullNameState(contactFullNameValidate);
    }
  }, [contactFullName]);

  useEffect(() => {
    if (phoneNumber.length > 0) {
      const phoneNumberValidate = validateFields.phoneNumberValidate(
        phoneNumber
      )
        ? "success"
        : "error";
      setPhoneNumberState(phoneNumberValidate);
    }
  }, [phoneNumber]);

  useEffect(() => {
    setExportToCsvState(false);
  }, [exportToCsvState]);

  useEffect(() => {
    if (printTableState) handlePrint();
  }, [printTableState]);

  const validFields = () => {
    const operatorNameValidate = validateFields.nameValidate(operatorName)
      ? "success"
      : "error";
    const operatorAddressValidate = validateFields.nameValidate(operatorAddress)
      ? "success"
      : "error";
    const contactFullNameValidate = validateFields.nameValidate(contactFullName)
      ? "success"
      : "error";
    const phoneNumberValidate = validateFields.phoneNumberValidate(phoneNumber)
      ? "success"
      : "error";
    setOperatorNameState(operatorNameValidate);
    setOperatorAddressState(operatorAddressValidate);
    setContactFullNameState(contactFullNameValidate);
    setPhoneNumberState(phoneNumberValidate);
    return (
      operatorNameValidate === "success" &&
      operatorAddressValidate === "success" &&
      contactFullNameValidate === "success" &&
      phoneNumberValidate === "success"
    );
  };

  const getProviderImage = providerId => {
    switch (providerId) {
      case 1: {
        return afkon;
      }
      case 2: {
        return charge;
      }
      case 3: {
        return greenspot;
      }
      case 29: {
        return afkon;
      }
      default: {
        return defaultOperator;
      }
    }
  };

  const addClick = () => {
    if (!validFields()) {
      return;
    }

    const URL = configData.SERVER_URL + "/api/provider/";        
    console.log("operatorToEdit", operatorToEdit);

    const defaultFormat = "YYYY-MM-DDTHH:mm:ss";
    const JSON_data = {
      providerName: operatorName,
      averagePrice: 0,
      address: operatorAddress,
      contact: contactFullName,
      phoneNumber: phoneNumber,
      LastChange: moment().format(defaultFormat),
      settingUp: moment().format(defaultFormat),
      active: true
    };
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertShowAddOperator(false);
        setSuccessAlert(true);
        setSuccessEditOperator(true);
        getOperatorsDataFromServer();
      })
      .catch(err => {
        console.error(err);
        setAlertShowAddOperator(false);
        setSuccessAlert(true);
        setSuccessEditOperator(false);
      });
  };

  const editOperatorClick = () => {
    if (!validFields()) {
      return;
    }
    const URL = configData.SERVER_URL + "api/provider/update/";

    console.log("operatorToEdit", operatorToEdit);
    const defaultFormat = "YYYY-MM-DDTHH:mm:ss";


    const JSON_data = {
      providerId: operatorToEdit.num,
      providerName: operatorName,
      averagePrice: operatorToEdit.activeStations,
      address: operatorAddress,
      contact: contactFullName,
      phoneNumber: phoneNumber,
      LastChange: moment().format(defaultFormat),
      active: userActive,
      settingUp: operatorToEdit.settingUp
    };
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertEditOperator(false);
        setSuccessAlert(true);
        setSuccessEditOperator(true);
        getOperatorsDataFromServer();
      })
      .catch(err => {
        console.error(err);
        setAlertEditOperator(false);
        setSuccessAlert(true);
        setSuccessEditOperator(false);
      });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    onAfterPrint: () => {
      setPrintTableState(false);
    }
  });

  const getDefaultPageSize = () => {
    if (printTableState || tablesLogic.DEFAULT_ITEMS_PAGE > data.length)
      return data.length;
    return tablesLogic.DEFAULT_ITEMS_PAGE;
  };

  const exportToCsv = () => {
    if (!exportToCsvState) return;
    const headers = [
      { label: "מספר", key: "num" },
      { label: "מפעיל", key: "operator" },
      { label: "תחנות פעילות", key: "activeStations" },
      { label: 'קוט"ש יומי', key: "dailyKWH" },
      { label: "רכבים יומיים", key: "dailyVehicles" },
      { label: "CO2 יומי", key: "dailyCO2" },
      { label: "פעיל'", key: "active" },
      { label: "כתובת", key: "address" },
      { label: "איש קשר", key: "contact" },
      { label: "מספר פאלפון", key: "phone" },
      { label: "תאריך יצירה", key: "settingsUp" },
      { label: "תאריך עדכון", key: "lastChange" }
    ];
    const csvData = operatorsTable.map(prop => {
      return {
        num: prop.providerId,
        operator: prop.providerName,
        activeStations: prop.availableStationsCounter,
        dailyKWH: prop.totalConsumptionTime,
        dailyVehicles: prop.totalChargedVehicles,
        active: prop.active ? "כן" : "לא",
        dailyCO2: prop.totalCO2Saved,
        address: prop.address,
        contact: prop.contact,
        phone: prop.phoneNumber,
        settingsUp: prop.settingUp,
        lastChange: prop.lastChange
      };
    });
    console.log("csvData", csvData);
    return (
      <CSVDownload
        filename={"operator data.csv"}
        headers={headers}
        data={csvData}
      />
    );
  };

  const data = operatorsTable.map((prop, key) => {
    const activeClassName = prop.active ? "green-active" : "red-active";
    return {
      id: key,
      num: prop.providerId,
      operator: prop.providerName,
      activeStations: prop.availableStationsCounter,
      dailyKWH: prop.totalConsumptionTime,
      dailyVehicles: prop.totalChargedVehicles,
      dailyCO2: prop.totalCO2Saved,
      address: prop.address,
      contact: prop.contact,
      phone: prop.phoneNumber,
      settingsUp: prop.settingUp,
      activeState: prop.active,
      logo: (
        <img
          className="provider-flag"
          src={getProviderImage(prop.providerId)}
          alt="provider flag"
          key={"flag"}
        />
      ),
      active: (
        <span className={activeClassName}>
          {/* {prop.active ? 'כן' : 'לא'} */}
        </span>
      ),
      lastChange: prop.lastChange,
      settingUp: prop.settingUp,
      actions: (
        <div className="actions-right edit-button">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let obj = data.find(o => o.id === key);
              console.log(obj);
              setOperatorToEdit(obj);
              setOperatorName(obj.operator);
              setOperatorAddress(obj.address);
              setContactFullName(obj.contact);
              setUserActive(obj.activeState);
              setPhoneNumber(obj.phone);
            }}
            color="primary"
            className="edit"
          >
            <Edit />
          </Button>
        </div>
      )
    };
  });


  const defaultPageSizeData = data.length > 10 ? 10 : data.length;  

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="operator-card-table">
            <CardHeader className="operator-header-table" color="primary" icon>
              <CardIcon className="operator-logo-table" color="primary">
                <PersonOutlined />
              </CardIcon>
              <Button
                justIcon
                round
                color="primary"
                className="settings-operator-logo"
                onClick={() => setShowSettings(!showSettings)}
              >
                <Settings />
              </Button>
              <h4 className={`title-operator-table ${classes.cardIconTitle}`}>
                 מפעילים
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "מספר",
                    accessor: "num"
                  },
                  {
                    Header: "לוגו",
                    accessor: "logo",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: "מפעיל",
                    accessor: "operator"
                  },
                  {
                    Header: "פעילות",
                    accessor: "activeStations"
                  },
                  {
                    Header: 'קוט"ש יומי',
                    accessor: "dailyKWH"
                  },
                  {
                    Header: "ר. יומיים",
                    accessor: "dailyVehicles"
                  },
                  {
                    Header: "יומי co2",
                    accessor: "dailyCO2"
                  },
                  {
                    Header: "פעיל",
                    accessor: "active",
                    sortable: false                   
                  },
                  {
                    Header: "כתובת",
                    accessor: "address"
                  },
                  {
                    Header: "א. קשר",
                    accessor: "contact"
                  },
                  {
                    Header: "טלפון נייד",
                    accessor: "phone"
                  },
                  {
                    Header: "ת. יצירה",
                    accessor: "settingsUp",
                    Cell: (d) => { return !!data[d.index].settingsUp ? moment(data[d.index].settingsUp).format('DD/MM/YYYY HH:mm'): null},
                    style: { direction: "ltr" }
                  },
                  {
                    Header: "ת. עדכון",
                    accessor:"lastChange",
                    Cell: (d) => { return !!data[d.index].lastChange ? moment(data[d.index].lastChange).format('DD/MM/YYYY HH:mm'): null},
                    style: { direction: "ltr" }
                  },
                  {
                    Header: "",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                ref={componentRef}
                data={data}                
                loading={loadingTable}
                PaginationComponent={MyPagination}
                showPagination={printTableState ? false : true}
                defaultPageSize={getDefaultPageSize()}
                key={printTableState ? data.length : currentPagination}
                className="-striped -highlight"
                // defaultFilterMethod={(filter, row) => {
                //   const id = filter.pivotId || filter.id;
                //   return row[id] !== null && row[id] !== undefined
                //     ? String(row[id].toLowerCase()).search(
                //         filter.value.toLowerCase()
                //       ) > 0
                //       ? true
                //       : false
                //     : true;
                // }}
              />              
            </CardBody>
              <div className="card-footer">
            <CardFooter>
                <span>נמצאו <span className="result-count">{
                  data.length
                }</span> תוצאות</span>                
            </CardFooter>          
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      {showSettings && (
        <div
          className="wrapper-dropdown"
          onClick={() => setShowSettings(false)}
        >
          <div className="fixed-plugin fixed-plugin-rtl">
            <div id="fixedPluginClasses " className="dropdown show">
              <div className="dropdown-menu">
                <div
                  className="add-operator-div"
                  onClick={() => {
                    setAlertShowAddOperator(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="add-operator-icon">
                    <PersonAdd /> הוספת מפעיל
                  </div>
                </div>
                <div
                  className="export-div"
                  onClick={() => {
                    setExportToCsvState(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="export-icon">
                    <GetApp className={classesButtonsStyle.icons} /> יצוא
                    מפעילים
                  </div>
                </div>

                <div
                  className="print-div"
                  onClick={() => {
                    setPrintTableState(true);
                    setShowSettings(false);
                  }}
                >
                  <span className="print-icon">
                    <Print className={classesButtonsStyle.icons} /> הדפסה
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertShowAddOperator && (
        <div className="popup-operator">
          <SweetAlert
            style={{
              width: "80%",
              position:'absolute',
              //  height: '80%', overflowY: 'auto',
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => setAlertShowAddOperator(false)}
            showConfirm={false}
            onCancel={() => {
              setAlertShowAddOperator(false);
            }}
            // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
          >
            <div
              className="close-icon-popup"
              onClick={() => setAlertShowAddOperator(false)}
            >
              <CloseIcon />
            </div>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <PersonOutlined />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    הוספת מפעיל
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      success={operatorNameState === "success"}
                      error={operatorNameState === "error"}
                      labelText="שם מפעיל*"
                      id="operatorName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setOperatorName(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={operatorAddressState === "success"}
                      error={operatorAddressState === "error"}
                      labelText="כתובת מפעיל*"
                      id="operatorAddress"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setOperatorAddress(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={contactFullNameState === "success"}
                      error={contactFullNameState === "error"}
                      labelText="שם מלא איש קשר*"
                      id="contactFullName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setContactFullName(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={phoneNumberState === "success"}
                      error={phoneNumberState === "error"}
                      labelText="פאלפון*"
                      id="phoneNumber"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setPhoneNumber(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <div className={classesValidateForm.formCategory}>
                      <small>*</small> שדות חובה
                    </div>
                    <Button
                      color="rose"
                      onClick={addClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {alertEditOperator && (
        <div className="popup-operator">
          <SweetAlert
            style={{
              width: "80%",
              position:"absolute",
              //  height: '80%', overflowY: 'auto',
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => setAlertEditOperator(false)}
            showConfirm={false}
            onCancel={() => {
              setAlertEditOperator(false);
            }}

            // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
          >
            <div
              className="close-icon-popup"
              onClick={() => setAlertEditOperator(false)}
            >
              <CloseIcon />
            </div>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <PersonOutlined />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    עריכת מפעיל
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      success={operatorNameState === "success"}
                      error={operatorNameState === "error"}
                      labelText="שם המפעיל*"
                      id="providerName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setOperatorName(event.target.value);
                        },
                        type: "string",
                        defaultValue: operatorToEdit.operator
                      }}
                    />

                    <CustomInput
                      success={operatorAddressState === "success"}
                      error={operatorAddressState === "error"}
                      labelText="כתובת המפעיל*"
                      id="addressOperator"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setOperatorAddress(event.target.value);
                        },
                        defaultValue: operatorToEdit.address,
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={contactFullNameState === "success"}
                      error={contactFullNameState === "error"}
                      labelText="שם איש קשר*"
                      id="contactOperator"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setContactFullName(event.target.value);
                        },
                        defaultValue: operatorToEdit.contact,
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={phoneNumberState === "success"}
                      error={phoneNumberState === "error"}
                      labelText="פאלפון איש קשר*"
                      id="contactFullName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setPhoneNumber(event.target.value);
                        },
                        defaultValue: operatorToEdit.phone,
                        type: "string"
                      }}
                    />
                    <div className="switch-trigger-div">
                      <span className="switch-label">פעיל: </span>
                      <Switch
                        checked={userActive}
                        onChange={() => setUserActive(!userActive)}
                        value="sidebarMini"
                        classes={{
                          switchBase: classesSweetAlert.switchBase,
                          checked: classesSweetAlert.switchChecked,
                          thumb: classesSweetAlert.switchIcon,
                          track: classesSweetAlert.switchBar
                        }}
                      />
                    </div>
                    <Button
                      color="rose"
                      onClick={editOperatorClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {successAlert && (
        <SweetAlert
          success={successEditOperator}
          error={!successEditOperator}
          style={{ display: "block", marginTop: "-100px" }}
          title={successEditOperator ? "הפעולה בוצעה בהצלחה" : "השליחה נכשלה"}
          onConfirm={() => setSuccessAlert(false)}
          onCancel={() => setSuccessAlert(false)}
          confirmBtnCssClass={
            classesSweetAlert.button + " " + classesSweetAlert.success
          }
        >
          {/* You clicked the button! */}
        </SweetAlert>
      )}
      {exportToCsv()}
    </div>
  );   
}
