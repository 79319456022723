import React from "react";
import { http } from "../HttpHandlers/http";

export const GetStationsData = async () => {
  const result = await http({ path: "/StationInfo/" });
  if (result.ok && result.body) {
    return result.body;
  } else {
    return [];
  }
};
