import React from 'react';
import { http } from '../HttpHandlers/http';


export const GetViewsReportData = async (date) => {
    const result = await http({ path: '/ViewsReport/' + date });
    if (result.ok && result.body) {
        return result.body;
    } else {
        return [];
    }
};
