import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import ShareLocation from "@mui/icons-material/ShareLocation";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker
} from "@material-ui/pickers";
import Settings from "@material-ui/icons/Settings";
import CalendarToday from "@material-ui/icons/CalendarToday";
//PersonAddAlt
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
// Print
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Icon from "@material-ui/core/Icon";
import { GetViewsReportData } from "../../Data/ViewsReportData";
import Switch from "@material-ui/core/Switch";
import SweetAlert from "react-bootstrap-sweetalert";
import MailOutline from "@material-ui/icons/MailOutline";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import stylesValidateForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { CSVDownload } from "react-csv";
import { useReactToPrint } from "react-to-print";
import DateFnsUtils from "@date-io/date-fns";
import Pagination from "components/Pagination/Pagination";
import TablesLogic from "./TablesLogic.js";

import MyPagination from "components/Pagination/MyPagination";

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import "./Operators.scss";
import moment from "moment";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStylesValidateForm = makeStyles(stylesValidateForm);
const useStylesSweetAlertStyle = makeStyles(sweetAlertStyle);
const useStylesButtonsStyle = makeStyles(buttonsStyle);

export default function ViewsReportTables() {
  const [viewsReportTable, setViewsReportTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showInactiveStations, setShowInactiveStations] = useState(false);
  const [alertShow, setAlert] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [stationName, setStationName] = useState("");
  const [stationNameState, setStationNameState] = useState("");
  const [stationAddress, setStationAddress] = useState("");
  const [stationAddressState, setStationAddressState] = useState("");
  const [plugs, setPlugs] = useState("");
  const [plugsState, setPlugsState] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [operatorNameState, setOperatorNameState] = useState("");
  // const [registerCheckbox, setregisterCheckbox] = useState(false);
  const [exportToCsvState, setExportToCsvState] = useState(false);
  const [printTableState, setPrintTableState] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(0);

  const tablesLogic = new TablesLogic();

  const getViewsReportDataFromServer = date => {
    const response = GetViewsReportData(date)
      .then(response => {
        console.log("GetViewsReportData", response);
        setViewsReportTable(response);
        setLoadingTable(false);
        setCurrentPagination(1);
      })
      .catch(err => {
        setLoadingTable(false);
        console.error(err);
      });
  };

  useEffect(() => {
    getViewsReportDataFromServer("");
  }, []);

  useEffect(() => {
    setExportToCsvState(false);
  }, [exportToCsvState]);

  useEffect(() => {
    if (printTableState) handlePrint();
  }, [printTableState]);

  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyUserNumber = value => {
    return value.length > 0;
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const addClick = () => {
    let closePopup = true;

    if (stationNameState === "") {
      closePopup = false;
      setStationNameState("error");
    }
    if (stationAddressState === "") {
      closePopup = false;
      setStationAddressState("error");
    }
    if (plugsState === "") {
      closePopup = false;
      setPlugsState("error");
    }
    if (operatorNameState === "") {
      closePopup = false;
      setOperatorNameState("error");
    }

    if (closePopup) {
      setAlert(false);
    }
  };

  const onDateChange = date => {
    const defaultFormat = "YYYY-MM-DDTHH:mm:ss";
    console.log(moment(date).format(defaultFormat));
    getViewsReportDataFromServer(moment(date).format(defaultFormat));
  };

  const getDefaultPageSize = () => {
    if (printTableState || tablesLogic.DEFAULT_ITEMS_PAGE > data.length)
      return data.length;
    return tablesLogic.DEFAULT_ITEMS_PAGE;
  };

  const exportToCsv = () => {
    if (!exportToCsvState) return;

    const headers = [
      { label: "תאריך", key: "date" },
      { label: "סוג", key: "type" },
      { label: "ניווט", key: "navigate" },
      { label: "זמן צפיה", key: "viewing_time" },
      { label: "פלטפורמה", key: "platform" }
    ];
    const csvData = viewsReportTable.map(prop => {
      return {
        date: prop.viewDate,
        type:
          prop.type === 11
            ? "SET TO LOCATION"
            : prop.type === 12
            ? "STATION CLICK"
            : prop.type === 13
            ? "STATION INFO"
            : "NAV TO STATION",
        navigate: prop.navigate,
        viewing_time: prop.viewTime,
        platform: prop.platform
      };
    });
    console.log("csvData", csvData);
    return (
      <CSVDownload
        filename={"operator data.csv"}
        headers={headers}
        data={csvData}
      />
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: " דוח צפיות",
    onAfterPrint: () => {
      setPrintTableState(false);
    }
  });

  const data = viewsReportTable.map((prop, key) => {
    return {
      id: key,
      date: prop.viewDate,
      type:
        prop.type === 11
          ? "SET TO LOCATION"
          : prop.type === 12
          ? "STATION CLICK"
          : prop.type === 13
          ? "STATION INFO"
          : "NAV TO STATION",
      navigate: prop.navigate,
      viewing_time: prop.viewTime,
      platform: prop.platform
    };
  });
  const classes = useStyles();
  const classesValidateForm = useStylesValidateForm();
  const classesSweetAlert = useStylesSweetAlertStyle();
  const classesButtonsStyle = useStylesButtonsStyle();
  const defaultPageSizeData = data.length > 10 ? 10 : data.length;

  return (
    <div onScroll={()=> setShowSettings(false)}>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="operator-card-table">
            <CardHeader className="operator-header-table" color="primary" icon>
              <CardIcon className="operator-logo-table" color="primary">
                <ShareLocation />
              </CardIcon>
              <Button
                justIcon
                round
                color="primary"
                className="settings-operator-logo"
                onClick={() => setShowSettings(!showSettings)}
              >
                <Settings />
              </Button>
              <h4 className={`title-operator-table ${classes.cardIconTitle}`}>
                {" "}
                דוח צפיות{" "}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "תאריך",
                    accessor: "date"
                  },
                  {
                    Header: "סוג",
                    accessor: "type"
                  },
                  {
                    Header: "ניווט",
                    accessor: "navigate"
                  },
                  {
                    Header: "זמן צפיה",
                    accessor: "viewing_time"
                  },
                  {
                    Header: "פלטפורמה",
                    accessor: "platform"
                  },
                  {
                    // Header: "Actions",
                    // accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                ref={componentRef}
                data={data}
                filterable={printTableState ? false : true}
                loading={loadingTable}
                PaginationComponent={MyPagination}
                showPagination={printTableState ? false : true}
                defaultPageSize={getDefaultPageSize()}
                key={printTableState ? data.length : currentPagination}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== null && row[id] !== undefined
                    ? String(row[id].toLowerCase()).search(
                        filter.value.toLowerCase()
                      ) > 0
                      ? true
                      : false
                    : true;
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {showSettings && (
        <div
          className="wrapper-dropdown"
          onClick={() => setShowSettings(false)}
        >
          <div className="fixed-plugin fixed-plugin-rtl">
            <div id="fixedPluginClasses " className="dropdown show">
              <div className="dropdown-menu">
                <div
                  className="add-operator-div"
                  onClick={() => {
                    setOpenDatePicker(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="add-operator-icon">
                    <CalendarToday />
                  </div>
                </div>
                <div
                  className="export-div"
                  onClick={() => {
                    setExportToCsvState(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="export-icon">
                    <GetApp className={classesButtonsStyle.icons} /> יצוא דוח
                  </div>
                </div>

                <div
                  className="print-div"
                  onClick={() => {
                    setPrintTableState(true);
                    setShowSettings(false);
                  }}
                >
                  <span className="print-icon">
                    <Print className={classesButtonsStyle.icons} /> הדפסה
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertShow && (
        <SweetAlert
          style={{
            width: "1000px",
            top: "32%",
            left: "34%",
            textAlign: "left",
            direction: "ltr"
          }}
          title=""
          onConfirm={() => setAlert(false)}
          showConfirm={false}
          onCancel={() => setAlert(false)}
          // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
        >
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <MailOutline />
                </CardIcon>
                <h4 className={classesValidateForm.cardIconTitle}>
                  Add operator
                </h4>
              </CardHeader>
              <CardBody>
                <form>
                  <CustomInput
                    success={stationNameState === "success"}
                    error={stationNameState === "error"}
                    labelText="Station name*"
                    id="stationName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyUserNumber(event.target.value)) {
                          setStationNameState("success");
                        } else {
                          setStationNameState("error");
                        }
                        setStationName(event.target.value);
                      },
                      type: "string"
                    }}
                  />
                  <CustomInput
                    success={stationAddressState === "success"}
                    error={stationAddressState === "error"}
                    labelText="Operator address*"
                    id="stationAddress"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyUserNumber(event.target.value)) {
                          setStationAddressState("success");
                        } else {
                          setStationAddressState("error");
                        }
                        setStationAddress(event.target.value);
                      },
                      type: "string"
                    }}
                  />
                  <CustomInput
                    success={plugsState === "success"}
                    error={plugsState === "error"}
                    labelText="plugs*"
                    id="plugs"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyUserNumber(event.target.value)) {
                          setPlugsState("success");
                        } else {
                          setPlugsState("error");
                        }
                        setPlugs(event.target.value);
                      },
                      type: "string"
                    }}
                  />
                  <CustomInput
                    success={operatorNameState === "success"}
                    error={operatorNameState === "error"}
                    labelText="Operator name*"
                    id="operatorName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyUserNumber(event.target.value)) {
                          setOperatorNameState("success");
                        } else {
                          setOperatorNameState("error");
                        }
                        setOperatorName(event.target.value);
                      },
                      type: "string"
                    }}
                  />

                  <div className={classesValidateForm.formCategory}>
                    <small>*</small> Required fields
                  </div>

                  <Button
                    color="rose"
                    onClick={addClick}
                    className={classesValidateForm.registerButton}
                  >
                    Add
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </SweetAlert>
      )}
      {exportToCsv()}
      {openDatePicker && (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              onClose={() => setOpenDatePicker(false)}
              value={null}
              maxDate={new Date()}
              onChange={onDateChange}
              open={openDatePicker}
              InputProps={{
                disableUnderline: true
              }}
              emptyLabel=""
              label=""
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
    </div>
  );
}
