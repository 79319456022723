import React from 'react';
import { http } from '../HttpHandlers/http';


export const GetOperationData = async () => {

    const result = await http({ path: '/providers/' });
    if (result.ok && result.body) {
        return result.body;
    } else {
        return [];
    }
};
