import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import Settings from "@material-ui/icons/Settings";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";

import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
// Print
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { GetUsersData } from "../../Data/UsersData";
import { GetPermissionData } from "../../Data/PermissionData";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";
import stylesValidateForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import stylesSelect from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import axios from "axios";

import { CSVDownload } from "react-csv";
import { useReactToPrint } from "react-to-print";
import TablesLogic from "./TablesLogic.js";
import MyPagination from "components/Pagination/MyPagination";

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import "./Operators.scss";
import ValidateFields from "views/Pages/ValidateFields";

import * as configData from "../../appConfig.json";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStylesValidateForm = makeStyles(stylesValidateForm);
const useStylesSweetAlertStyle = makeStyles(sweetAlertStyle);
const useStylesSelect = makeStyles(stylesSelect);
const useStylesButtonsStyle = makeStyles(buttonsStyle);
const validateFields = new ValidateFields();

export default function UsersTables() {
  const [usersTable, setUsersTable] = useState([]);
  const [permissionTable, setPermissionTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [alertShowAddUser, setAlertShowAddUser] = useState(false);
  const [nameOfUser, setNameOfUser] = useState("");
  const [nameOfUserState, setNameOfUserState] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameState, setUserNameState] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userEmailState, setUserEmailState] = useState("");
  const [userPass, setUserPass] = useState("");
  const [userPassState, setUserPassState] = useState("");
  const [departementNameState, setDepartementNameState] = useState("");
  const [departementName, setDepartementName] = useState("");
  const [phoneNumberUser, setPhoneNumberUser] = useState("");
  const [phoneNumberUserState, setPhoneNumberUserState] = useState("");
  const [permissionSelect, setPermissionSelect] = useState("");
  const [userActive, setUserActive] = useState(true);
  const [permissionSelectState, setPermissionSelectState] = useState("");
  const [alertEditUser, setAlertEditUser] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successEditUser, setSuccessEditUser] = useState(false);
  const [exportToCsvState, setExportToCsvState] = useState(false);
  const [printTableState, setPrintTableState] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(0);

  const tablesLogic = new TablesLogic();
  useEffect(() => {
    getUsersDataFromServer();
    GetPermissionDataFromServer();
  }, []);

  const getUsersDataFromServer = () => {
    const response = GetUsersData()
      .then(response => {
        setUsersTable(response);
        setLoadingTable(false);
        setCurrentPagination(1);
      })
      .catch(err => {
        setLoadingTable(false);
        console.error(err);
      });
  };

  const GetPermissionDataFromServer = () => {
    const response = GetPermissionData()
      .then(response => {
        console.log("GetPermissionData", response);
        setPermissionTable(response);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (userToEdit) {
      setAlertEditUser(true);
    }
  }, [userToEdit]);

  useEffect(() => {
    setExportToCsvState(false);
  }, [exportToCsvState]);

  useEffect(() => {
    if (!alertEditUser) {
      setPermissionSelect("");
      setNameOfUser("");
      setNameOfUserState("");
      setUserName("");
      setUserNameState("");
      setUserEmail("");
      setUserEmailState("");
      setUserPass("");
      setUserPassState("");
      setDepartementName("");
      setDepartementNameState("");
      setPhoneNumberUser("");
      setPhoneNumberUserState("");
      setPermissionSelect("");
      setPermissionSelectState("");
      setUserActive(true);
    }
  }, [alertEditUser]);

  useEffect(() => {
    if (nameOfUser.length > 0) {
      const nameOfUserValidate = validateFields.nameValidate(nameOfUser)
        ? "success"
        : "error";
      setNameOfUserState(nameOfUserValidate);
    }
  }, [nameOfUser]);

  useEffect(() => {
    if (userName.length > 0) {
      const userNameValidate = validateFields.nameValidate(userName)
        ? "success"
        : "error";
      setUserNameState(userNameValidate);
    }
  }, [userName]);

  useEffect(() => {
    if (userPass.length > 0) {
      const userPassValidate = validateFields.passwordValidate(userPass)
        ? "success"
        : "error";
      setUserPassState(userPassValidate);
    }
  }, [userPass]);

  useEffect(() => {
    if (userEmail.length > 0) {
      const userEmailValidate = validateFields.emailValidate(userEmail)
        ? "success"
        : "error";
      setUserEmailState(userEmailValidate);
    }
  }, [userEmail]);

  useEffect(() => {
    if (departementName.length > 0) {
      const departementNameValidate = validateFields.nameValidate(
        departementName
      )
        ? "success"
        : "error";
      setDepartementNameState(departementNameValidate);
    }
  }, [departementName]);

  useEffect(() => {
    if (phoneNumberUser.length > 0) {
      const phoneNumberUserValidate = validateFields.phoneNumberValidate(
        phoneNumberUser
      )
        ? "success"
        : "error";
      setPhoneNumberUserState(phoneNumberUserValidate);
    }
  }, [phoneNumberUser]);

  useEffect(() => {
    if (permissionSelect > 0) {
      const permissionSelectValidate = validateFields.numberValidate(
        permissionSelect
      )
        ? "success"
        : "error";
      setPermissionSelectState(permissionSelectValidate);
    }
  }, [permissionSelect]);

  const validFields = () => {
    const nameOfUserValidate = validateFields.nameValidate(nameOfUser)
      ? "success"
      : "error";
    const userNameValidate = validateFields.nameValidate(userName)
      ? "success"
      : "error";
    const userEmailValidate = validateFields.emailValidate(userEmail)
      ? "success"
      : "error";
    const departementNameValidate = validateFields.nameValidate(departementName)
      ? "success"
      : "error";
    const phoneNumberUserValidate = validateFields.phoneNumberValidate(
      phoneNumberUser
    )
      ? "success"
      : "error";
    const permissionSelectValidate = validateFields.numberValidate(
      permissionSelect
    )
      ? "success"
      : "error";
    setNameOfUserState(nameOfUserValidate);
    setUserNameState(userNameValidate);
    setUserEmailState(userEmailValidate);
    setDepartementNameState(departementNameValidate);
    setPhoneNumberUserState(phoneNumberUserValidate);
    setPermissionSelectState(permissionSelectValidate);
    return (
      nameOfUserValidate === "success" &&
      userNameValidate === "success" &&
      userEmailValidate === "success" &&
      departementNameValidate === "success" &&
      phoneNumberUserValidate === "success" &&
      permissionSelectValidate === "success"
    );
  };

  useEffect(() => {
    if (!alertShowAddUser) {
      setPermissionSelect("");
      setNameOfUser("");
      setNameOfUserState("");
      setUserName("");
      setUserNameState("");
      setUserEmail("");
      setUserEmailState("");
      setUserPass("");
      setUserPassState("");
      setDepartementName("");
      setDepartementNameState("");
      setPhoneNumberUser("");
      setPhoneNumberUserState("");
      setPermissionSelect("");
      setPermissionSelectState("");
      setUserActive(true);
    }
  }, [alertShowAddUser]);

  useEffect(() => {
    if (printTableState) handlePrint();
  }, [printTableState]);

  const addUserClick = () => {
    if (!validFields()) {
      return;
    }
    //const URL = "https://cellocharge.com/portalDashboard/api/users/";
     const URL = configData.SERVER_URL + "api/users";
    const defaultFormat = "YYYY-MM-DDTHH:mm:ss";
    const JSON_data = {
      name: nameOfUser,
      departement: departementName,
      phone: phoneNumberUser,
      accessGroup: permissionSelect,
      pass: userPass,
      created: moment().format(defaultFormat),
      email: userEmail,
      userName: userName,
      lastLogin: moment().format(defaultFormat),
      active: userActive
    };

    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertShowAddUser(false);
        setSuccessAlert(true);
        setSuccessEditUser(true);
        getUsersDataFromServer();
      })
      .catch(err => {
        console.error(err);
        setAlertShowAddUser(false);
        setSuccessAlert(true);
        setSuccessEditUser(false);
      });
  };

  const editUserClick = () => {
    if (!validFields()) {
      return;
    }
    const URL = configData.SERVER_URL + "api/user";
    console.log("userToEdit", userToEdit);

    const JSON_data = {
      id: userToEdit.num,
      name: nameOfUser,
      departement: departementName,
      phone: phoneNumberUser,
      accessGroup: permissionSelect,
      userName: userName,
      created: userToEdit.created,
      lastLogin: userToEdit.lastLogin,
      email: userEmail,
      active: userActive
    };
    console.log("JSON_data", JSON_data);

    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertEditUser(false);
        setSuccessAlert(true);
        setSuccessEditUser(true);
        getUsersDataFromServer();
      })
      .catch(err => {
        console.error(err);
        setAlertEditUser(false);
        setSuccessAlert(true);
        setSuccessEditUser(false);
      });
  };

  const getDefaultPageSize = () => {
    if (printTableState || tablesLogic.DEFAULT_ITEMS_PAGE > data.length)
      return data.length;
    return tablesLogic.DEFAULT_ITEMS_PAGE;
  };

  const exportToCsv = () => {
    if (!exportToCsvState) return;

    const headers = [
      { label: "שם המשתמש", key: "userName" },
      { label: "חברה", key: "departement" },
      { label: "אימייל", key: "email" },
      { label: "כניסה אחרונה", key: "lastLogin" },
      { label: "פאל'", key: "phone" },
      { label: "הרשאה'", key: "accessGroup" },
      { label: "פעיל'", key: "active" },
      { label: "תאריך יצירה", key: "created" }
    ];
    const csvData = usersTable.map(prop => {
      return {
        userName: prop.userName,
        email: prop.email,
        departement: prop.departement,
        lastLogin: prop.lastLogin,
        accessGroup: getGroupName(prop.accessGroup),
        active: prop.active ? "כן" : "לא",
        phone: prop.phone,
        created: prop.created
      };
    });
    console.log("csvData", csvData);
    return (
      <CSVDownload
        filename={"operator data.csv"}
        headers={headers}
        data={csvData}
      />
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "משתמשים",
    onAfterPrint: () => {
      setPrintTableState(false);
    }
  });

  const getGroupName = id => {
    const group = permissionTable.find(permission => permission.id === id);
    return group?.name;
  };

  const getPermissionSelect = () => {
    let permissionSelectList = [];

    permissionTable.forEach(item => {
      const menuItem = (
        <MenuItem
          key={item.id}
          classes={{
            root: classesSelect.selectMenuItem + " menu-item-select",
            selected: classesSelect.selectMenuItemSelected
          }}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      );
      permissionSelectList.push(menuItem);
    });
    return permissionSelectList;
  };

  const data = usersTable.map((prop, key) => {
    return {
      id: key,
      num: prop.id,
      userName: prop.userName,
      departement: prop.departement,
      lastLogin: prop.lastLogin,
      phone: prop.phone,
      pass: prop.pass,
      created: prop.created,
      accessGroup: getGroupName(prop.accessGroup),
      accessGroupId: prop.accessGroup,
      name: prop.name,
      email: prop.email,
      active: prop.active ? "כן" : "לא",
      activeState: prop.active,
      actions: (
        <div className="actions-right edit-button">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let obj = data.find(o => o.id === key);
              console.log(obj);
              setUserToEdit(obj);
              setUserName(obj.userName);
              setDepartementName(obj.departement);
              setPhoneNumberUser(obj.phone);
              setNameOfUser(obj.name);
              setUserEmail(obj.email);
              setPermissionSelect(obj.accessGroupId);
              setUserActive(obj.activeState);
            }}
            color="primary"
            className="edit"
          >
            <Edit />
          </Button>
        </div>
      )
    };
  });
  const classes = useStyles();
  const classesValidateForm = useStylesValidateForm();
  const classesSweetAlert = useStylesSweetAlertStyle();
  const classesButtonsStyle = useStylesButtonsStyle();
  const defaultPageSizeData = data.length > 10 ? 10 : data.length;
  const classesSelect = useStylesSelect();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="operator-card-table">
            <CardHeader className="operator-header-table" color="primary" icon>
              <CardIcon className="operator-logo-table" color="primary">
                <PersonOutlined />
              </CardIcon>
              <Button
                justIcon
                round
                color="primary"
                className="settings-operator-logo"
                onClick={() => setShowSettings(!showSettings)}
              >
                <Settings />
              </Button>
              <h4 className={`title-operator-table ${classes.cardIconTitle}`}>
                {" "}
                משתמשים{" "}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "שם",
                    accessor: "name"
                  },
                  {
                    Header: "שם המשתמש",
                    accessor: "userName"
                  },
                  {
                    Header: "אימייל",
                    accessor: "email"
                  },
                  {
                    Header: "חברה",
                    accessor: "departement"
                  },
                  {
                    Header: "הרשאה",
                    accessor: "accessGroup"
                  },
                  {
                    Header: "כניסה אחרונה",
                    accessor: "lastLogin"
                  },

                  {
                    Header: "פאל",
                    accessor: "phone"
                  },
                  {
                    Header: "פעיל",
                    accessor: "active"
                  },
                  {
                    Header: "תאריך יצירה",
                    accessor: "created"
                  },
                  {
                    Header: "עדכון",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                ref={componentRef}
                data={data}
                filterable={printTableState ? false : true}
                loading={loadingTable}
                PaginationComponent={MyPagination}
                showPagination={printTableState ? false : true}
                defaultPageSize={getDefaultPageSize()}
                key={printTableState ? data.length : currentPagination}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== null && row[id] !== undefined
                    ? String(row[id].toLowerCase()).search(
                        filter.value.toLowerCase()
                      ) > 0
                      ? true
                      : false
                    : true;
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {showSettings && (
        <div
          className="wrapper-dropdown"
          onClick={() => setShowSettings(false)}
        >
          <div className="fixed-plugin fixed-plugin-rtl">
            <div id="fixedPluginClasses " className="dropdown show">
              <div className="dropdown-menu">
                <div
                  className="add-operator-div"
                  onClick={() => {
                    setAlertShowAddUser(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="add-operator-icon">
                    <PersonAdd /> הוספת משתמש
                  </div>
                </div>
                <div
                  className="export-div"
                  onClick={() => {
                    setExportToCsvState(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="export-icon">
                    <GetApp className={classesButtonsStyle.icons} /> יצוא
                    משתמשים
                  </div>
                </div>

                <div
                  className="print-div"
                  onClick={() => {
                    setPrintTableState(true);
                    setShowSettings(false);
                  }}
                >
                  <span className="print-icon">
                    <Print className={classesButtonsStyle.icons} /> הדפסה
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertShowAddUser && (
        <div className="popup-operator">
          <SweetAlert
            style={{
              height: "90%",
              position: "absolute",
              //  height: '80%', overflowY: 'auto',
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => setAlertShowAddUser(false)}
            showConfirm={false}
            onCancel={() => setAlertShowAddUser(false)}
            // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
          >
            <div
              className="close-icon-popup"
              onClick={() => setAlertShowAddUser(false)}
            >
              <CloseIcon />
            </div>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <PersonOutlined />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    הוספת משתמש
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      success={nameOfUserState === "success"}
                      error={nameOfUserState === "error"}
                      labelText="שם*"
                      id="nameOfUser"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setNameOfUser(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={userNameState === "success"}
                      error={userNameState === "error"}
                      labelText="שם משתמש*"
                      id="addUserName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setUserName(event.target.value);
                        },
                        autoComplete: "off",
                        type: "string",
                        defaultValue: ""
                      }}
                    />
                    <CustomInput
                      success={departementNameState === "success"}
                      error={departementNameState === "error"}
                      labelText="חברה*"
                      id="departement"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setDepartementName(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={userEmailState === "success"}
                      error={userEmailState === "error"}
                      labelText="אימייל*"
                      helperText={
                        userEmailState === "error"
                          ? "יש להזין כתובת אימייל חוקית, לא ניתן להוסיף מייל שכבר קיים במערכת."
                          : ""
                      }
                      id="userEmail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setUserEmail(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={phoneNumberUserState === "success"}
                      error={phoneNumberUserState === "error"}
                      labelText="מספר פאלפון*"
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setPhoneNumberUser(event.target.value);
                        },
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={userPassState === "success"}
                      error={userPassState === "error"}
                      helperText={
                        userPassState === "error"
                          ? "סיסמא חייבת להכיל לפחות שש תווים, הסיסמא חייבת להכיל לפחות: אות גדולה, אות קטנה, מספר, ותו מיוחד"
                          : ""
                      }
                      labelText="סיסמא*"
                      id="addUserPassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setUserPass(event.target.value);
                        },
                        autoComplete: "off",
                        type: "password"
                      }}
                    />

  {/*                   <div className="switch-trigger-div">
                      <span className="switch-label">פעיל: </span>
                      <Switch
                        checked={userActive}
                        onChange={() => setUserActive(!userActive)}
                        value="sidebarMini"
                        classes={{
                          switchBase: classesSweetAlert.switchBase,
                          checked: classesSweetAlert.switchChecked,
                          thumb: classesSweetAlert.switchIcon,
                          track: classesSweetAlert.switchBar
                        }}
                      />
                    </div> */}

                    <div className="permission-fields">
                      <FormControl
                        fullWidth
                        success={permissionSelectState === "success"}
                        error={permissionSelectState === "error"}
                        className={classesSelect.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          // className={classes.selectLabel}
                          className="select-label"
                        >
                          בחר הרשאה
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className:
                              classesSelect.selectMenu + " menu-props-class"
                          }}
                          classes={{
                            select: classesSelect.select + " select-value"
                          }}
                          value={permissionSelect}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                            onChange: event => {
                              setPermissionSelect(event.target.value);
                            }
                          }}
                        >
                          {getPermissionSelect()}
                        </Select>
                      </FormControl>
                    </div>

                    <div className={classesValidateForm.formCategory}>
                      <small>*</small> שדות חובה
                    </div>

                    <Button
                      color="rose"
                      onClick={addUserClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {alertEditUser && (
        //   dailyKWH: prop.totalConsumptionTime,
        //   dailyVehicles: prop.totalChargedVehicles,
        //   dailyCO2: prop.totalCO2Saved,
        <div className="popup-operator">
          <SweetAlert
            style={{
              width: "80%",
              position: "absolute",
              //  height: '80%', overflowY: 'auto',
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => setAlertEditUser(false)}
            showConfirm={false}
            onCancel={() => {
              setAlertEditUser(false);
              setUserToEdit(null);
            }}

            // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
          >
            <div
              className="close-icon-popup"
              onClick={() => setAlertEditUser(false)}
            >
              <CloseIcon />
            </div>
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <PersonOutlined />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    עריכת משתמש
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      success={nameOfUserState === "success"}
                      error={nameOfUserState === "error"}
                      labelText="שם*"
                      id="nameOfUser"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setNameOfUser(event.target.value);
                        },
                        defaultValue: userToEdit.name,
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={userNameState === "success"}
                      error={userNameState === "error"}
                      labelText="שם משתמש*"
                      id="addUserName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setUserName(event.target.value);
                        },
                        defaultValue: userToEdit.userName
                        // disabled: true,
                      }}
                    />
                    <CustomInput
                      success={userEmailState === "success"}
                      error={userEmailState === "error"}
                      helperText={
                        userEmailState === "error"
                          ? "יש להזין כתובת אימייל חוקית"
                          : ""
                      }
                      labelText="אימייל*"
                      id="userEmail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setUserEmail(event.target.value);
                        },
                        type: "string",
                        defaultValue: userToEdit.email
                      }}
                    />
                    <CustomInput
                      success={departementNameState === "success"}
                      error={departementNameState === "error"}
                      labelText="חברה*"
                      id="departement"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setDepartementName(event.target.value);
                        },
                        type: "string",
                        defaultValue: userToEdit.departement
                      }}
                    />

                    <CustomInput
                      success={phoneNumberUserState === "success"}
                      error={phoneNumberUserState === "error"}
                      labelText="פאלפון*"
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setPhoneNumberUser(event.target.value);
                        },
                        defaultValue: userToEdit.phone,
                        type: "string"
                      }}
                    />

                    <div className="switch-trigger-div">
                      <span className="switch-label">פעיל: </span>
                      <Switch
                        checked={userActive}
                        onChange={() => setUserActive(!userActive)}
                        value="sidebarMini"
                        classes={{
                          switchBase: classesSweetAlert.switchBase,
                          checked: classesSweetAlert.switchChecked,
                          thumb: classesSweetAlert.switchIcon,
                          track: classesSweetAlert.switchBar
                        }}
                      />
                    </div>
                    <div className="permission-fields">
                      <FormControl
                        fullWidth
                        success={permissionSelectState === "success"}
                        error={permissionSelectState === "error"}
                        className={classesSelect.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          // className={classes.selectLabel}
                          className="select-label"
                        >
                          בחר הרשאה
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className:
                              classesSelect.selectMenu + " menu-props-class"
                          }}
                          classes={{
                            select: classesSelect.select + " select-value"
                          }}
                          value={permissionSelect}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                            onChange: event => {
                              setPermissionSelect(event.target.value);
                            }
                          }}
                        >
                          {getPermissionSelect()}
                        </Select>
                      </FormControl>
                    </div>

                    <Button
                      color="rose"
                      onClick={editUserClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {successAlert && (
        <SweetAlert
          success={successEditUser}
          error={!successEditUser}
          style={{ display: "block", marginTop: "-100px" }}
          title={successEditUser ? "הפעולה בוצעה בהצלחה" : "הפעולה נכשלה"}
          onConfirm={() => setSuccessAlert(false)}
          onCancel={() => setSuccessAlert(false)}
          confirmBtnCssClass={
            classesSweetAlert.button + " " + classesSweetAlert.success
          }
        >
          {/* You clicked the button! */}
        </SweetAlert>
      )}
      {exportToCsv()}
    </div>
  );
}
