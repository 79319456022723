import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from '@mui/material/Box';
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { ENumPages } from '../../Pages/EnumPages'


const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customSelectStyle,
  // ...customCheckboxRadioSwitch
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: Number.isInteger(props.openingPageID) ? props.openingPageID : '',
      simpleSelectState: Number.isInteger(props.openingPageID) ? 'success' : '',
      permissionName: props.groupName ? props.groupName : '',
      permissionNameState: props.groupName ? 'success' : '',
    };
  }
  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (
      this.state.permissionNameState === "success"
      && this.state.simpleSelectState === "success"
    ) {
      this.props.getGroupNameAndSelectedPage(this.state.permissionName, this.state.simpleSelect)
      return true;
    } else {
      if (this.state.permissionNameState !== "success") {
        this.setState({ permissionNameState: "error" });
      }
      if (this.state.simpleSelectState !== "success") {
        this.setState({ simpleSelectState: "error" });
      }

    }
    return false;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value, simpleSelectState: "success" });
  };
  getPageSelect = (classes) => {
    let pagesSelect = [];

    ENumPages.forEach(item => {
      if (item.pageType === 1) return;
      const menuItem = (
        <MenuItem
          key={item.id}
          classes={{
            root: classes.selectMenuItem + ' menu-item-select',
            selected: classes.selectMenuItemSelected
          }}
          value={item.id}
        >
          {item.pageDescription}
        </MenuItem>
      )
      pagesSelect.push(menuItem);
    });
    return (pagesSelect)
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              {'יש להזין שם הרשאה ודף פתיחה'}
            </h4>
          </GridItem>
          <GridItem xs={12} sm={6} className='permission-fields'>
            <CustomInput
              success={this.state.permissionNameState === "success"}
              error={this.state.permissionNameState === "error"}
              labelText={
                <span>
                  שם הרשאה <small>(שדה חובה)</small>
                </span>
              }
              id="permissionName"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "permissionName", "length", 3),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Face className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                defaultValue: this.state.permissionName
              }}
            />
            <FormControl
              fullWidth
              success={this.state.simpleSelectState === "success"}
              error={this.state.simpleSelectState === "error"}
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                // className={classes.selectLabel}
                className='select-label'
              >
                בחר דף פתיחה
              </InputLabel>

              <Select
                MenuProps={{
                  className: classes.selectMenu + ' menu-props-class'
                }}
                classes={{
                  select: classes.select + ' select-value'
                }}
                value={this.state.simpleSelect}
                onChange={this.handleSimple}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >

                {this.getPageSelect(classes)}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>


      </div>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
