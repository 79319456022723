import React from 'react';
import { http } from '../HttpHandlers/http';


export const GetUsersData = async () => {

    const result = await http({ path: '/users/' });
    if (result.ok && result.body) {
        return result.body;
    } else {
        return [];
    }
};

export var LogOnUserName = "adid";
