import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import EvStation from "@material-ui/icons/EvStation";
import Settings from "@material-ui/icons/Settings";
import PersonAdd from "@material-ui/icons/PersonAdd";
//PersonAddAlt
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
// Print
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { GetPermissionData } from "../../Data/PermissionData";
import SweetAlert from "react-bootstrap-sweetalert";
import stylesValidateForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Edit from "@material-ui/icons/Edit";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Wizard from "components/Wizard/Wizard.js";
// import GridContainer from "components/Grid/GridContainer.js";

import Step1 from "../Forms/WizardSteps/Step1";
import Step2 from "../Forms/WizardSteps/Step2.js";
import Step3 from "../Forms/WizardSteps/Step3.js";

// import { dataTable } from "variables/general.js";
import { CSVDownload } from "react-csv";
import { useReactToPrint } from "react-to-print";
import TablesLogic from "./TablesLogic.js";
import MyPagination from "components/Pagination/MyPagination";
import { GetUsersData } from "../../Data/UsersData";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import "./Operators.scss";
import axios from "axios";

import * as configData from "../../appConfig.json";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStylesValidateForm = makeStyles(stylesValidateForm);
const useStylesSweetAlertStyle = makeStyles(sweetAlertStyle);
const useStylesButtonsStyle = makeStyles(buttonsStyle);

export default function PermissionsTables() {
  const [permissionTable, setPermissionTable] = useState([]);
  const [usersTable, setUsersTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [alertShowAddPermission, setAlertShowAddPermission] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [openingPageID, setOpeningPageID] = useState("");
  const [pagesSelected, setPagesSelected] = useState([]);

  const [phoneNumberUserState, setPhoneNumberUserState] = useState("");
  const [alertEditPermission, setAlertEditPermission] = useState(false);
  const [permissionToEdit, setPermissionToEdit] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successEditPermission, setSuccessEditPermission] = useState(false);
  const [exportToCsvState, setExportToCsvState] = useState(false);
  const [printTableState, setPrintTableState] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(0);

  const tablesLogic = new TablesLogic();

  const GetPermissionDataFromServer = () => {
    const response = GetPermissionData()
      .then(response => {
        console.log("GetPermissionData", response);
        setPermissionTable(response);
        setLoadingTable(false);
        setCurrentPagination(1);
      })
      .catch(err => {
        setLoadingTable(false);
        console.error(err);
      });
  };

  const getUsersDataFromServer = () => {
    const response = GetUsersData()
      .then(response => {
        setUsersTable(response);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    GetPermissionDataFromServer();
    getUsersDataFromServer();
  }, []);

  useEffect(() => {
    if (permissionToEdit) {
      setAlertEditPermission(true);
    }
  }, [permissionToEdit]);

  useEffect(() => {
    if (printTableState) handlePrint();
  }, [printTableState]);

  useEffect(() => {
    setExportToCsvState(false);
  }, [exportToCsvState]);

  const verifyPermissionName = value => {
    return value.length > 0;
  };

  const editPermissionClick = () => {
    setAlertEditPermission(false);
    setSuccessAlert(true);
    setSuccessEditPermission(true);
  };

  const getGroupNameAndSelectedPage = (groupName, openingPageID) => {
    setGroupName(groupName);
    setOpeningPageID(openingPageID);
  };

  const getSelectedPage = selectedPage => {
    if (!selectedPage.includes(openingPageID)) {
      selectedPage.push(openingPageID);
    }
    setPagesSelected(selectedPage);
  };

  const getUserName = id => {
    const user = usersTable.find(user => user.id == id);
    return user?.name;
  };

  const finishButtonClick = () => {
    console.log("finishButtonClick");
    const URL =
      configData.SERVER_URL + "api/MenuCategories/AccessGroup/";      

    const JSON_data = {
      groupName: groupName,
      OpeningPageID: openingPageID,
      pages: pagesSelected
    };
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertShowAddPermission(false);
        setSuccessAlert(true);
        setSuccessEditPermission(true);
        GetPermissionDataFromServer();
      })
      .catch(err => {
        console.error(err);
        setAlertShowAddPermission(false);
        setSuccessAlert(true);
        setSuccessEditPermission(false);
      });
  };

  const editFinishButtonClick = () => {
    const URL =
    configData.SERVER_URL + "api/MenuCategories/AccessGroup/update/";      

    const JSON_data = {
      groupName: groupName,
      OpeningPageID: openingPageID,
      pages: pagesSelected,
      id: permissionToEdit.id
    };
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertEditPermission(false);
        setPermissionToEdit(null);
        setSuccessAlert(true);
        setSuccessEditPermission(true);
        GetPermissionDataFromServer();
      })
      .catch(err => {
        console.error(err);
        setAlertEditPermission(false);
        setPermissionToEdit(null);
        setSuccessAlert(true);
        setSuccessEditPermission(false);
      });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "הרשאות",
    onAfterPrint: () => {
      setPrintTableState(false);
    }
  });

  const getDefaultPageSize = () => {
    if (printTableState || tablesLogic.DEFAULT_ITEMS_PAGE > data.length)
      return data.length;
    return tablesLogic.DEFAULT_ITEMS_PAGE;
  };

  const exportToCsv = () => {
    if (!exportToCsvState) return;

    const headers = [
      { label: "id", key: "num" },
      { label: "שם הרשאה", key: "permissionName" },
      { label: "דף פתיחה", key: "openingPage" },
      { label: "מקור", key: "source" },
      { label: "משתמשים", key: "users" },
      { label: "סטטוס", key: "status" },
      { label: "תאריך יצירה", key: "settingsUp" },
      { label: "תאריך עדכון", key: "lastChange" }
    ];
    const csvData = permissionTable.map(prop => {
      return {
        num: prop.id,
        permissionName: prop.name,
        openingPage: prop.openingPage,
        source: prop.source,
        users: prop.users,
        status: prop.status,
        settingsUp: prop.created,
        lastChange: prop.lastChanged
      };
    });
    console.log("csvData", csvData);
    return (
      <CSVDownload
        filename={"operator data.csv"}
        headers={headers}
        data={csvData}
      />
    );
  };

  const data = permissionTable.map((prop, key) => {
    return {
      id: key,
      num: prop.id,
      permissionName: prop.name,
      openingPage: prop.openingPage,
      source: prop.source,
      user: getUserName(prop.source),
      users: prop.users,
      status: prop.status,
      openingPageID: prop.openingPageID,
      pages: prop.pages,
      settingsUp: prop.created,
      lastChange: prop.lastChanged,
      actions: (
        <div className="actions-right edit-button">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let obj = data.find(o => o.id === key);
              console.log(obj);
              setPermissionToEdit(obj);
              setGroupName(prop.name);
              setOpeningPageID(prop.openingPageID);
              setPagesSelected(prop.pages.map(page => page.pageID));
            }}
            color="primary"
            className="edit"
          >
            <Edit />
          </Button>
        </div>
      )
    };
  });
  const classes = useStyles();
  const classesValidateForm = useStylesValidateForm();
  const classesSweetAlert = useStylesSweetAlertStyle();
  const classesButtonsStyle = useStylesButtonsStyle();
  const defaultPageSizeData = data.length > 10 ? 10 : data.length;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="operator-card-table">
            <CardHeader className="operator-header-table" color="primary" icon>
              <CardIcon className="operator-logo-table" color="primary">
                <AdminPanelSettingsIcon />
              </CardIcon>
              <Button
                justIcon
                round
                color="primary"
                className="settings-operator-logo"
                onClick={() => setShowSettings(!showSettings)}
              >
                <Settings />
              </Button>
              <h4 className={`title-operator-table ${classes.cardIconTitle}`}>
                {" "}
                הרשאות{" "}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "מספר",
                    accessor: "num"
                  },
                  {
                    Header: "שם הרשאה",
                    accessor: "permissionName"
                  },
                  {
                    Header: "דף פתיחה",
                    accessor: "openingPage"
                  },
                  {
                    Header: "מקור",
                    accessor: "user"
                  },
                  {
                    Header: "משתמשים",
                    accessor: "users"
                  },
                  {
                    Header: "סטטוס",
                    accessor: "status"
                  },
                  {
                    Header: "תאריך יצירה",
                    accessor: "settingsUp",
                    sortable: true
                  },
                  {
                    Header: "תאריך עדכון",
                    accessor: "lastChange",
                    sortable: true
                  },
                  {
                    Header: "עדכון",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                ref={componentRef}
                data={data}
                filterable={printTableState ? false : true}
                loading={loadingTable}
                PaginationComponent={MyPagination}
                showPagination={printTableState ? false : true}
                defaultPageSize={getDefaultPageSize()}
                key={printTableState ? data.length : currentPagination}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== null && row[id] !== undefined
                    ? String(row[id].toLowerCase()).search(
                        filter.value.toLowerCase()
                      ) > 0
                      ? true
                      : false
                    : true;
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {showSettings && (
        <div
          className="wrapper-dropdown"
          onClick={() => setShowSettings(false)}
        >
          <div className="fixed-plugin fixed-plugin-rtl">
            <div id="fixedPluginClasses " className="dropdown show">
              <div className="dropdown-menu">
                <div
                  className="add-operator-div"
                  onClick={() => {
                    setAlertShowAddPermission(true);
                    setShowSettings(false);
                    setGroupName("");
                    setOpeningPageID("");
                    setPagesSelected([]);
                  }}
                >
                  <div className="add-operator-icon">
                    <PersonAdd /> הוספת הרשאה
                  </div>
                </div>
                <div
                  className="export-div"
                  onClick={() => {
                    setExportToCsvState(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="export-icon">
                    <GetApp className={classesButtonsStyle.icons} /> יצוא הרשאות
                  </div>
                </div>

                <div
                  className="print-div"
                  onClick={() => {
                    setPrintTableState(true);
                    setShowSettings(false);
                  }}
                >
                  <span className="print-icon">
                    <Print className={classesButtonsStyle.icons} /> הדפסה
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertShowAddPermission && (
        <SweetAlert
          // style={{ width: "1200px", top: '32%', left: '30%', textAlign: 'left', direction: 'ltr', position: 'absolute', overflowY: 'auto', maxHeight: '90%' }}
          style={{
            width: "80%",
            //  height: '80%', overflowY: 'auto',
            position: "absolute",
            overflowY: "auto",
            top: "35%",
            left: "auto",
            textAlign: "right",
            direction: "rtl"
          }}
          title=""
          onConfirm={() => setAlertShowAddPermission(false)}
          showConfirm={false}
          onCancel={() => setAlertShowAddPermission(false)}
          // confirmBtnCssClass={classesValidateForm.button + " " + classesValidateForm.success}
        >
          <div
            className="close-icon-popup"
            onClick={() => setAlertShowAddPermission(false)}
          >
            <CloseIcon />
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <Wizard
                className="step-header"
                validate
                getGroupNameAndSelectedPage={getGroupNameAndSelectedPage}
                getSelectedPage={getSelectedPage}
                groupName={groupName}
                openingPageID={openingPageID}
                pagesSelected={pagesSelected}
                finishButtonClick={finishButtonClick}
                steps={[
                  {
                    stepName: "הרשאת פרטים",
                    stepComponent: Step1,
                    stepId: "about"
                  },
                  {
                    stepName: "הרשאת עמוד",
                    stepComponent: Step2,
                    stepId: "account"
                  },
                  {
                    stepName: "סיכום ואישור",
                    stepComponent: Step3,
                    stepId: "address"
                  }
                ]}
                title="הוספת הרשאה"
                subtitle=""
              />
            </GridItem>
          </GridContainer>
        </SweetAlert>
      )}
      {alertEditPermission && (
        <SweetAlert
          // style={{ width: "1200px", top: '32%', left: '30%', textAlign: 'left', direction: 'ltr', position: 'absolute', overflowY: 'auto', maxHeight: '90%' }}
          style={{
            width: "80%",
            position: "absolute",
            //  height: '80%', overflowY: 'auto',
            overflowY: "auto",
            top: "30%",
            left: "auto",
            textAlign: "right",
            direction: "rtl"
          }}
          title=""
          onConfirm={() => setAlertEditPermission(false)}
          showConfirm={false}
          onCancel={() => {
            setAlertEditPermission(false);
            setPermissionToEdit(null);
          }}
        >
          <div
            className="close-icon-popup"
            onClick={() => setAlertEditPermission(false)}
          >
            <CloseIcon />
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <Wizard
                className="step-header"
                validate
                getGroupNameAndSelectedPage={getGroupNameAndSelectedPage}
                getSelectedPage={getSelectedPage}
                groupName={groupName}
                openingPageID={openingPageID}
                pagesSelected={pagesSelected}
                finishButtonClick={editFinishButtonClick}
                steps={[
                  {
                    stepName: "הרשאת פרטים",
                    stepComponent: Step1,
                    stepId: "about"
                  },
                  {
                    stepName: "הרשאת עמוד",
                    stepComponent: Step2,
                    stepId: "account"
                  },
                  {
                    stepName: "סיכום ואישור",
                    stepComponent: Step3,
                    stepId: "address"
                  }
                ]}
                title="עריכת הרשאה"
                subtitle=""
              />
            </GridItem>
          </GridContainer>
        </SweetAlert>
      )}
      {successAlert && (
        <SweetAlert
          success={successEditPermission}
          error={!successEditPermission}
          style={{ display: "block", marginTop: "-100px" }}
          title={successEditPermission ? "הפעולה בוצעה בהצלחה" : "הפעולה נכשלה"}
          onConfirm={() => setSuccessAlert(false)}
          onCancel={() => setSuccessAlert(false)}
          confirmBtnCssClass={
            classesSweetAlert.button + " " + classesSweetAlert.success
          }
        >
          {/* You clicked the button! */}
        </SweetAlert>
      )}
      {exportToCsv()}
    </div>
  );
}
