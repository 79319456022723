import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WidgetsIcon from "@material-ui/icons/Widgets";
import StorageIcon from '@mui/icons-material/Storage';
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import EvStation from "@material-ui/icons/EvStation";
import { ENumPages } from '../../Pages/EnumPages'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Settings from "@material-ui/icons/Settings";
import ShareLocation from '@mui/icons-material/ShareLocation';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import './steps.scss';

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const DASHBOARD_ID = 2;
const CELLOCHARGE_SITE = 3;
const OPERATING_TOOLS_ID = 4;
const MANAGE_TOOLS_ID = 8;
const SYSTEM_TOOLS_ID = 12;
const OPERATORS_ID = 5;
const STATIONS_ID = 6;
const VIEWS_REPORTS_ID = 11;
const LOCALIZATION_ID = 13;
const USERS_TABLES_ID = 14;
const PERMISSIONS_ID = 15;
//Permissions


class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCategoriesId: props.pagesSelected
    };
    this.handleChange = this.handleChange.bind(this)
  }


  handleChange = (event, ids) => {
    let selectCategoriesId = this.state.selectCategoriesId;
    if (event.target.checked) {
      ids.forEach(id => {
        selectCategoriesId.push(id)
      });
    }
    else {
      ids.forEach(id => {
        selectCategoriesId = selectCategoriesId.filter(selId => selId != id)
      });
    }
    this.setState({ selectCategoriesId });
  };

  testFunction = (event, id) => {
    console.log('event.target.checked', id);
    console.log('event.target.checked', id);

  }


  isValidated() {
    this.props.getSelectedPage(this.state.selectCategoriesId)
    return true;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>לחץ על הקטגוריות הניתנות לצפיה עבור רמת הרשאה זו</h4>
        <p className={classes.infoText}>לאחר בחירת קטגוריה, ניתן יהיה לסמן את הדפים בתת הקטגוריה.</p>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={(e) => { this.handleChange(e, [DASHBOARD_ID]) }}
                    checked={this.state.selectCategoriesId.find(id => id === DASHBOARD_ID) ? true : false}
                    checkedIcon={
                      <i className={classes.iconCheckboxIcon}>
                        <DashboardIcon />
                      </i>
                    }
                    icon={
                      <i className={classes.iconCheckboxIcon} >
                        <DashboardIcon />
                      </i>
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>לוח מחוונים</h6>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={(e) => { this.handleChange(e, [CELLOCHARGE_SITE]) }}
                    checked={this.state.selectCategoriesId.find(id => id === CELLOCHARGE_SITE) ? true : false}
                    checkedIcon={
                      <i className={classes.iconCheckboxIcon}>
                        <LocationOnIcon />
                      </i>
                    } icon={
                      <i className={classes.iconCheckboxIcon}>
                        <LocationOnIcon />
                      </i>
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>אתר רספונסיבי</h6>
                </div>
              </GridItem>

              <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    checked={this.state.selectCategoriesId.find(id => id === OPERATING_TOOLS_ID) ? true : false}
                    onClick={(e) => { this.handleChange(e, [OPERATING_TOOLS_ID, OPERATORS_ID, STATIONS_ID]) }}
                    checkedIcon={
                      <i className={classes.iconCheckboxIcon}>
                        <WidgetsIcon />
                      </i>
                    }
                    icon={
                      <i className={classes.iconCheckboxIcon}>
                        <WidgetsIcon />
                      </i>
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>כלי תפעול</h6>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    checked={this.state.selectCategoriesId.find(id => id === MANAGE_TOOLS_ID) ? true : false}
                    tabIndex={-1}
                    onClick={(e) => { this.handleChange(e, [MANAGE_TOOLS_ID, VIEWS_REPORTS_ID]) }}
                    checkedIcon={
                      <i className={classes.iconCheckboxIcon}>
                        <StorageIcon />
                      </i>
                    } icon={
                      <i className={classes.iconCheckboxIcon}>
                        <StorageIcon />
                      </i>
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>כלי ניהול</h6>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    checked={this.state.selectCategoriesId.find(id => id === SYSTEM_TOOLS_ID) ? true : false}
                    onClick={(e) => { this.handleChange(e, [SYSTEM_TOOLS_ID, LOCALIZATION_ID, USERS_TABLES_ID, PERMISSIONS_ID]) }}
                    checkedIcon={
                      <i className={classes.iconCheckboxIcon}>
                        <Settings />
                      </i>
                    } icon={
                      <i className={classes.iconCheckboxIcon}>
                        <Settings />
                      </i>
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>כלי מערכת</h6>
                </div>
              </GridItem>

            </GridContainer>
          </GridItem>
        </GridContainer>
        {this.state.selectCategoriesId.find(selId => selId === OPERATING_TOOLS_ID) &&
          <div>

            <p className='sub-categories-title'>בחר את הדפים תחת קטגורית "כלי תפעול"</p>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <div className="step2-choiche">
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.selectCategoriesId.find(id => id === OPERATORS_ID) ? true : false}
                        onClick={(e) => { this.handleChange(e, [OPERATORS_ID]) }}
                        className='sub-categories-circle'
                        checkedIcon={
                          <i className={classes.iconCheckboxIcon}>
                            <PersonOutlined />
                          </i>
                        }
                        icon={
                          <i className={classes.iconCheckboxIcon} >
                            <PersonOutlined />
                          </i>
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox
                        }}
                      />
                      <h6>מפעילים</h6>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className="step2-choiche">
                      <Checkbox
                        tabIndex={-1}
                        className='sub-categories-circle'
                        checked={this.state.selectCategoriesId.find(id => id === STATIONS_ID) ? true : false}
                        onClick={(e) => { this.handleChange(e, [STATIONS_ID]) }}
                        checkedIcon={
                          <i className={classes.iconCheckboxIcon}>
                            <EvStation />
                          </i>
                        } icon={
                          <i className={classes.iconCheckboxIcon}>
                            <EvStation />
                          </i>
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox
                        }}
                      />
                      <h6>תחנות</h6>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        }
        {this.state.selectCategoriesId.find(selId => selId === MANAGE_TOOLS_ID) &&
          <div>
            <p className='sub-categories-title'>בחר את הדפים תחת קטגורית "כלי ניהול"</p>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <div className="step2-choiche">
                      <Checkbox
                        tabIndex={-1}
                        className='sub-categories-circle'
                        checked={this.state.selectCategoriesId.find(id => id === VIEWS_REPORTS_ID) ? true : false}
                        onClick={(e) => { this.handleChange(e, [VIEWS_REPORTS_ID]) }}
                        checkedIcon={
                          <i className={classes.iconCheckboxIcon}>
                            <ShareLocation />
                          </i>
                        }
                        icon={
                          <i className={classes.iconCheckboxIcon} >
                            <ShareLocation />
                          </i>
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox
                        }}
                      />
                      <h6>דוח צפיות</h6>
                    </div>
                  </GridItem>

                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        }
        {this.state.selectCategoriesId.find(selId => selId === SYSTEM_TOOLS_ID) &&
          <div>
            <p className='sub-categories-title'>בחר את הדפים תחת קטגורית "כלי מערכת"</p>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} lg={10}>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <div className="step2-choiche">
                      <Checkbox
                        tabIndex={-1}
                        className='sub-categories-circle'
                        checked={this.state.selectCategoriesId.find(id => id === LOCALIZATION_ID) ? true : false}
                        onClick={(e) => { this.handleChange(e, [LOCALIZATION_ID]) }}
                        checkedIcon={
                          <i className={classes.iconCheckboxIcon}>
                            <PersonOutlined />
                          </i>
                        }
                        icon={
                          <i className={classes.iconCheckboxIcon} >
                            <PersonOutlined />
                          </i>
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox
                        }}
                      />
                      <h6>לוקליזציה</h6>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className="step2-choiche">
                      <Checkbox
                        tabIndex={-1}
                        className='sub-categories-circle'
                        checked={this.state.selectCategoriesId.find(id => id === USERS_TABLES_ID) ? true : false}
                        onClick={(e) => { this.handleChange(e, [USERS_TABLES_ID]) }}
                        checkedIcon={
                          <i className={classes.iconCheckboxIcon}>
                            <PersonOutlined />
                          </i>
                        }
                        icon={
                          <i className={classes.iconCheckboxIcon} >
                            <PersonOutlined />
                          </i>
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox
                        }}
                      />
                      <h6>משתמשים</h6>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <div className="step2-choiche">
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.selectCategoriesId.find(id => id === PERMISSIONS_ID) ? true : false}
                        className='sub-categories-circle'
                        onClick={(e) => { this.handleChange(e, [PERMISSIONS_ID]) }}
                        checkedIcon={
                          <i className={classes.iconCheckboxIcon}>
                            <AdminPanelSettingsIcon />
                          </i>
                        }
                        icon={
                          <i className={classes.iconCheckboxIcon} >
                            <AdminPanelSettingsIcon />
                          </i>
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox
                        }}
                      />
                      <h6>הרשאות</h6>
                    </div>
                  </GridItem>

                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        }

      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step2);
