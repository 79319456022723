import React from "react";

//ValidateFields
export default class ValidateFields extends React.Component {

    emailValidate = (email) => {        
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    userNameValidate = (userName) => {
        return userName.match(
            /^[a-zA-Z0-9]*$/
        )
    }

    nameValidate = (name) => {
        return (name.length > 1)
    }

    numberValidate = (number) => {
        return (!isNaN(number))
    }

    phoneNumberValidate = (phone) => {
        const phoneNumberCheck = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return phoneNumberCheck.test(phone);

    }

    passwordValidate(pw) {
        return /[A-Z]/.test(pw) &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[^A-Za-z0-9]/.test(pw) &&
            pw.length > 5;
    }

}
