import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Settings from "@material-ui/icons/Settings";
import GetApp from "@material-ui/icons/GetApp";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { CSVDownload } from "react-csv";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import Print from "@material-ui/icons/Print";
import { useReactToPrint } from "react-to-print";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { GetLocalizationData } from "../../Data/LocalizationData.js";
import SweetAlert from "react-bootstrap-sweetalert";
import MailOutline from "@material-ui/icons/MailOutline";
import CustomInput from "components/CustomInput/CustomInput.js";
import stylesValidateForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Edit from "@material-ui/icons/Edit";
import TablesLogic from "./TablesLogic.js";
import MyPagination from "components/Pagination/MyPagination";
import axios from "axios";
import { GetUsersData } from "../../Data/UsersData";
import configData from "../../appConfig.json"

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import "./Operators.scss";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
const useStylesValidateForm = makeStyles(stylesValidateForm);
const useStylesSweetAlertStyle = makeStyles(sweetAlertStyle);
const useStylesButtonsStyle = makeStyles(buttonsStyle);

export default function LocalizationTables() {
  const [localizationTable, setLocalizationTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [exportToCsvState, setExportToCsvState] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [alertEditLocalization, setAlertEditLocalization] = useState(false);
  const [localizationToEdit, setLocalizationToEdit] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successEditLocalization, setSuccessEditLocalization] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [value, setValue] = useState("");
  const [valueState, setValueState] = useState("");
  const [comment, setComment] = useState("");
  const [commentState, setCommentState] = useState("");
  const [usersTable, setUsersTable] = useState([]);
  const [printTableState, setPrintTableState] = useState(false);
  const tablesLogic = new TablesLogic();

  const GetLocalizationDataFromServer = () => {
    const response = GetLocalizationData()
      .then(response => {
        console.log("GetLocalizationData", response);
        setLocalizationTable(response);
        setLoadingTable(false);
        setCurrentPagination(1);
      })
      .catch(err => {
        setLoadingTable(false);
        console.error(err);
      });
  };

  const getUsersDataFromServer = () => {
    const response = GetUsersData()
      .then(response => {
        setUsersTable(response);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    GetLocalizationDataFromServer();
    getUsersDataFromServer();
  }, []);

  useEffect(() => {
    if (localizationToEdit) {
      setAlertEditLocalization(true);
    }
  }, [localizationToEdit]);

  const verifyUserNumber = value => {
    return value.length > 0;
  };

  useEffect(() => {
    setExportToCsvState(false);
  }, [exportToCsvState]);

  useEffect(() => {
    if (printTableState) handlePrint();
  }, [printTableState]);

  const editLocalizationClick = () => {
    const URL = configData.SERVER_URL + "api/localization/";
    const JSON_data = {
      id: localizationToEdit.localId,
      updateValue: value,
      comment: comment
    };
    axios
      .post(URL, JSON_data)
      .then(response => {
        console.info(response);
        setAlertEditLocalization(false);
        setSuccessAlert(true);
        setSuccessEditLocalization(true);
        GetLocalizationDataFromServer();
        setTimeout(setSuccessAlert, 2000);
      })
      .catch(err => {
        console.error(err);
        setAlertEditLocalization(false);
        setSuccessAlert(true);
        setSuccessEditLocalization(false);
        setTimeout(setSuccessAlert, 2000);
      });
  };

  const getUserName = id => {
    const user = usersTable.find(user => user.id == id);
    return user?.name;
  };

  const getDefaultPageSize = () => {
    if (printTableState || tablesLogic.DEFAULT_ITEMS_PAGE > data.length)
      return data.length;
    return tablesLogic.DEFAULT_ITEMS_PAGE;
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "לוקליזציה",
    onAfterPrint: () => {
      setPrintTableState(false);
    }
  });

  const exportToCsv = () => {
    if (!exportToCsvState) return;
    const headers = [
      { label: "מספר", key: "localId" },
      { label: "סוג שדה", key: "resourceType" },
      { label: "ערך שדה", key: "resourceValue" },
      { label: "קוד שפה", key: "cultureCode" },
      { label: "עודכן", key: "user" },
      { label: "מזהה שדה", key: "resourceKey" },
      { label: "תאריך עדכון", key: "lastUpdate" },
      { label: "הערות", key: "comment" }
    ];
    const csvData = localizationTable.map(prop => {
      return {
        localId: prop.id,
        resourceType: prop.resourceType,
        resourceValue: prop.resourceValue,
        cultureCode: prop.cultureCode,
        user: getUserName(prop.source),
        resourceKey: prop.source,
        lastUpdate: prop.lastUpdate,
        comment: prop.comment
      };
    });
    console.log("csvData", csvData);
    return (
      <CSVDownload
        filename={"localization data.csv"}
        headers={headers}
        data={csvData}
      />
    );
  };

  const data = localizationTable.map((prop, key) => {
    return {
      id: key,
      localId: prop.id,
      resourceType: prop.resourceType,
      resourceValue: prop.resourceValue,
      cultureCode: prop.cultureCode,
      user: getUserName(prop.source),
      resourceKey: prop.source,
      lastUpdate: prop.lastUpdate,
      comment: prop.comment,
      actions: (
        <div className="actions-right edit-button">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let obj = data.find(o => o.id === key);
              console.log(obj);
              setLocalizationToEdit(obj);
              setValue(obj.resourceValue);
              setComment(obj.comment);
            }}
            color="primary"
            className="edit"
          >
            <Edit />
          </Button>
        </div>
      )
    };
  });
  const classes = useStyles();
  const classesValidateForm = useStylesValidateForm();
  const classesSweetAlert = useStylesSweetAlertStyle();
  const classesButtonsStyle = useStylesButtonsStyle();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card className="operator-card-table">
            <CardHeader className="operator-header-table" color="primary" icon>
              <CardIcon className="operator-logo-table" color="primary">
                <PersonOutlined />
              </CardIcon>
              <Button
                justIcon
                round
                color="primary"
                className="settings-operator-logo"
                onClick={() => setShowSettings(!showSettings)}
              >
                <Settings />
              </Button>
              <h4 className={`title-operator-table ${classes.cardIconTitle}`}>
                {" "}
                לוקליזציה{" "}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "מספר",
                    accessor: "localId"
                  },
                  {
                    Header: "סוג שדה",
                    accessor: "resourceType"
                  },
                  {
                    Header: "משתמש",
                    accessor: "user"
                  },
                  {
                    Header: "ת. עדכון",
                    accessor: "lastUpdate"
                  },
                  {
                    Header: "ערך שדה",
                    accessor: "resourceValue"
                  },
                  {
                    Header: "מזהה שפה",
                    accessor: "cultureCode"
                  },
                  {
                    Header: "הערות",
                    accessor: "comment"
                  },
                  {
                    Header: "עדכון",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
               
                ref={componentRef}
                data={data}
                filterable={true}
                loading={loadingTable}
                PaginationComponent={MyPagination}
                showPagination={printTableState ? false : true}
                defaultPageSize={getDefaultPageSize()}
                key={printTableState ? data.length : currentPagination}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== null && row[id] !== undefined
                      ? String(row[id].toLowerCase()).search(
                          filter.value.toLowerCase()
                        ) > 0
                        ? true
                        : false
                      : true;
                  }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {showSettings && (
        <div
          className="wrapper-dropdown"
          onClick={() => setShowSettings(false)}
        >
          <div className="fixed-plugin fixed-plugin-rtl">
            <div id="fixedPluginClasses " className="dropdown show">
              <div className="dropdown-menu">
                <div
                  className="export-div"
                  onClick={() => {
                    setExportToCsvState(true);
                    setShowSettings(false);
                  }}
                >
                  <div className="export-icon">
                    <GetApp className={classesButtonsStyle.icons} /> יצוא
                  </div>
                </div>

                <div
                  className="print-div"
                  onClick={() => {
                    setPrintTableState(true);
                    setShowSettings(false);
                  }}
                >
                  <span className="print-icon">
                    <Print className={classesButtonsStyle.icons} /> הדפסה
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {alertEditLocalization && (
        <div className="popup-operator">
          <SweetAlert
            style={{
              width: "80%",
              position: "absolute",
              //  height: '80%', overflowY: 'auto',
              overflowY: "auto",
              top: "35%",
              left: "auto",
              textAlign: "right",
              direction: "rtl"
            }}
            title=""
            onConfirm={() => setAlertEditLocalization(false)}
            showConfirm={false}
            onCancel={() => {
              setAlertEditLocalization(false);
              setLocalizationToEdit(null);
            }}
          >
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="rose" icon className="card-header">
                  <CardIcon color="rose" className="rtl-logo">
                    <PersonOutlined />
                  </CardIcon>
                  <h4
                    className={`title-popup ${classesValidateForm.cardIconTitle}`}
                  >
                    עריכת שדה
                  </h4>
                </CardHeader>
                <CardBody>
                  <form>
                    <CustomInput
                      success={valueState === "success"}
                      error={valueState === "error"}
                      id="editValue"
                      labelText="ערך שדה"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setValueState("success");
                          } else {
                            setValueState("error");
                          }
                          setValue(event.target.value);
                        },
                        defaultValue: localizationToEdit.resourceValue,
                        type: "string"
                      }}
                    />
                    <CustomInput
                      success={commentState === "success"}
                      error={commentState === "error"}
                      labelText="הערה"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyUserNumber(event.target.value)) {
                            setCommentState("success");
                          } else {
                            setCommentState("error");
                          }
                          setComment(event.target.value);
                        },
                        defaultValue: localizationToEdit.comment,
                        type: "string"
                      }}
                    />

                    <Button
                      color="rose"
                      onClick={editLocalizationClick}
                      className={`trl-button ${classesValidateForm.registerButton}`}
                    >
                      שמור
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </SweetAlert>
        </div>
      )}
      {successAlert && (
        <SweetAlert
          success={successEditLocalization}
          error={!successEditLocalization}
          onConfirm={() => setSuccessAlert(false)}
          style={{ display: "block", marginTop: "-100px" }}
          title={
            successEditLocalization ? "הפעולה בוצעה בהצלחה" : "הפעולה נכשלה"
          }
          showConfirm={false}
          onCancel={() => setSuccessAlert(false)}
          confirmBtnCssClass={
            classesSweetAlert.button + " " + classesSweetAlert.success
          }
        >
          {!successEditLocalization && <div>אנא נסה שוב מאוחר יותר</div>}
        </SweetAlert>
      )}
      {exportToCsv()}
    </div>
  );
}
