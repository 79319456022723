import React from 'react';
import { http } from '../HttpHandlers/http';


export const GetPermissionData = async () => {

    const result = await http({ path: '/permissions/' });
    if (result.ok && result.body) {
        return result.body;
    } else {
        return [];
    }
};
