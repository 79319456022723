export const ENumPages = [
    {
        id: 2,
        value: 'dashboard',
        pageDescription: 'לוח מחוונים',
        pageType: 2
    },
    {
        id: 4,
        pageDescription: "כלי תיפעול",
        pageType: 1
    },
    {
        id: 3,
        value: 'cellocharge-site',
        pageDescription: 'אתר רספונסיבי',
        pageType: 2
    },
    {
        id: 5,
        value: 'operators',
        pageDescription: 'מפעילים',
        pageType: 2
    },
    {
        id: 6,
        value: 'stations',
        pageDescription: 'תחנות',
        pageType: 2
    },
    {
        id: 7,
        value: 'login-page',
        pageDescription: 'התחברות',
        pageType: 1
    },
    {
        id: 8,
        pageDescription: "כלי ניהול",
        pageType: 1
    },
    {
        id: 11,
        value: 'views-report',
        pageDescription: 'דוח צפיות',
        pageType: 2
    },
    {
        id: 12,
        pageDescription: "כלי מערכת",
        pageType: 1
    },
    {
        id: 13,
        value: 'localization',
        pageDescription: 'לוקליזציה',
        pageType: 2
    },
    {
        id: 14,
        value: 'users-tables',
        pageDescription: 'משתמשים',
        pageType: 2
    },
    {
        id: 15,
        value: 'permissions-tables',
        pageDescription: 'הרשאות',
        pageType: 2
    },
]
