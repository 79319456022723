import React from 'react';
import { http } from '../HttpHandlers/http';

//  export interface LocalizationData {
//     id: number;
//     resourceType: string;
//     resourceValue: string;
//     cultureCode: string;
//     SystemID: string;
//     comment: string;
//     actions?:(itm:LocalizationData)=>JSX.Element;
//   //  created:Date;
//   //actions?:(itm:LocalizationData)=>JSX.Element;

//   };

// export interface LocalizationDataFromServer {
//   id: number;
//   resourceType: string;
//   resourceValue: string;
//   cultureCode: string;
//   SystemID: string;
//   comment: string;
//   actions?:(itm:LocalizationData)=>JSX.Element;
//  // created:Date;
// };



// var  tmpLocalization: LocalizationData[] = [
//   {
//    // id: 1,
//     resourceType: "Adi",
//     resourceValue: "Adi",
//     cultureCode: "Adi",
//     comment: "asdasd",
//     SystemID:"afdfd",

//     //created::new Date(),
//     //actions: new Element(),
//     //actions:"",
//   },
//   {
//    // id: 2,
//     resourceType: "Adi",
//     resourceValue: "Adi",
//     cultureCode: "Adi",
//     comment: "asdasd",
//     SystemID:"afdfd",
//     //actions:"",
//     //created:new Date(),
//   }];

var localizationDataFromServerResponse = [];
/*    {
     id: 1,
     resourceType: "Adi",
     resourceValue: "Adi",
     cultureCode: "Adi",
     comment: "asdasd",
     //actions:"",
    // created:new Date(),
   },
   {
     id: 2,
     resourceType: "Adi",
     resourceValue: "Adi",
     cultureCode: "Adi",
     comment: "asdasd",
     //actions:"",
    // created:new Date(),
   }]; */

export const GetLocalizationArr = () => {
  return localizationDataFromServerResponse
};

export const mapLocalizationFromServer = (
  localization,
) => ({
  ...localization,

  // comment: " - ",
  //actions:"",      
});


export const GetLocalizationData = async () => {

  const result = await http({ path: '/localization' });
  if (result.ok && result.body) {
    localizationDataFromServerResponse = result.body.map(mapLocalizationFromServer);
    return result.body.map(mapLocalizationFromServer);
  } else {
    return [];
  }
};

//export type { LocalizationData };