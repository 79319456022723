//CellochargeSite
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as configData from "../../appConfig.json";

export default function CellochargeSite(props) {
  const { dashboard } = props;
  
   let src = configData.SITE_URL + "?portal=true";
  if (dashboard) {
    src = src + "&dashboard=true";
  }
  const height = dashboard ? "40vh" : "80vh";
  const border = dashboard ? "none" : "1px solid";
  return (
    <div className="cellocharge-site">
      <iframe
        src={src}
        style={{
          height: height,
          width: "103%",
          border: border
        }}
      ></iframe>
    </div>
  );
}

CellochargeSite.propTypes = {
  dashboard: PropTypes.bool
};
